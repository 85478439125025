<div class="bottom-sheet" [formGroup]="userForm">
  <div class="bottom-sheet__wrapper">
    <div class="bottom-sheet__header">
      <h3 class="bottom-sheet__title">User Settings</h3>
      <button mat-icon-button (click)="closeBottomSheet()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div class="bottom-sheet__subtitle">Manage your profile information</div>
    <div class="bottom-sheet__content">
      <div mat-dialog-content class="user-settings__content">
        <section class="user-settings__section">
          <div class="user-settings__section-title">General</div>
          <div class="user-settings__form">
            <div class="user-settings__row">
              <mat-label>First Name</mat-label>
              <mat-form-field
                class="user-settings__form-field"
                appearance="outline"
              >
                <input matInput formControlName="first_name" />
              </mat-form-field>
            </div>
            <div class="user-settings__row">
              <mat-label>Last Name</mat-label>
              <mat-form-field
                class="user-settings__form-field"
                appearance="outline"
              >
                <input matInput formControlName="last_name" />
              </mat-form-field>
            </div>
            <div class="user-settings__row">
              <mat-label>Company</mat-label>
              <mat-form-field
                class="user-settings__form-field"
                appearance="outline"
              >
                <input matInput formControlName="company" />
              </mat-form-field>
            </div>
            <div class="user-settings__row">
              <mat-label>Phone</mat-label>
              <mat-form-field
                class="user-settings__form-field"
                appearance="outline"
              >
                <input matInput formControlName="phone" />
              </mat-form-field>
            </div>

            <div class="user-settings__row">
              <mat-label>Job Title</mat-label>
              <mat-form-field
                class="user-settings__form-field"
                appearance="outline"
              >
                <input matInput formControlName="job_title" />
              </mat-form-field>
            </div>
          </div>
        </section>

        <section class="user-settings__section">
          <div class="user-settings__section-title role">Role</div>
          <div class="user-settings__section-subtitle role">
            Changing the role will influence the chat's responses.
          </div>
          <div class="user-settings__form">
            <div class="user-settings__row">
              <mat-form-field
                appearance="outline"
                class="user-settings__form-field role"
              >
                <mat-select formControlName="role">
                  <mat-option
                    *ngFor="let role of rolesOption"
                    [value]="role.value"
                  >
                    {{ role.label }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </section>

        <section class="user-settings__section">
          <div class="user-settings__section-title">Security</div>
          <div class="user-settings__security">
            <span>Change password</span>
            <button mat-icon-button (click)="navigateChangePassword()">
              <mat-icon>edit</mat-icon>
            </button>
          </div>
        </section>
      </div>
    </div>
  </div>
  <div class="bottom-sheet__actions">
    <button
      mat-raised-button
      class="cancel-button"
      (click)="closeBottomSheet()"
    >
      CANCEL
    </button>
    <button
      mat-raised-button
      class="primary-button"
      (click)="saveUserSettings()"
    >
      SAVE
    </button>
  </div>
</div>
