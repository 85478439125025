<div class="user-mobile__navigation">
  <div class="user-mobile__navigation__header">
    <mat-icon
      class="user-mobile__navigation__header__icon"
      (click)="closeMobileUserDrawer()"
      >close</mat-icon
    >
  </div>
  <div>
    <h4 class="user__h4">User Settings</h4>
  </div>
  <div class="user__settings-container">
    <div class="user__settings-score">
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header class="users__container-panel">
            <mat-panel-title>
              <div class="scores__container">
                <div class="scores__container-header">
                  <span class="scores-icon">
                    <svg-icon src="assets/icon/scores.svg"></svg-icon>
                  </span>
                  <span class="scores__span">Scores</span>
                </div>
                <span class="scores__container-current">{{
                  currentScore.name
                }}</span>
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <mat-list>
            <div class="scores__list-header">Default Scores</div>
            <mat-list-item
              class="score__list-item"
              *ngFor="let score of yukkaScores"
              [class.current]="score?.uid === currentScore.uid"
              (click)="setCurrentScore(score)"
              matRipple
              [matRippleColor]="'rgba(255, 255, 255, 0.2)'"
            >
              <span class="user__span">{{ score.name }}</span>
            </mat-list-item>
          </mat-list>

          <mat-list *ngIf="customScores?.length > 0">
            <div class="scores__list-header">My Custom Scores</div>
            <mat-list-item
              class="score__list-item"
              *ngFor="let score of customScores"
              [class.current]="score?.uid === currentScore.uid"
              (click)="setCurrentScore(score)"
              matRipple
              [matRippleColor]="'rgba(255, 255, 255, 0.2)'"
            >
              <span class="user__span">{{ score.name }}</span>
            </mat-list-item>
          </mat-list>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
    <button
      class="user__settings-button"
      mat-raised-button
      [routerLink]="[]"
      (click)="openUserSettingsPopup()"
    >
      <span>Settings</span>
      <mat-icon>settings</mat-icon>
    </button>
    <button
      class="user__settings-button logout"
      mat-raised-button
      routerLink="/logout"
    >
      <span> Logout </span>
      <mat-icon>exit_to_app</mat-icon>
    </button>
  </div>
</div>
