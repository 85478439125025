<mat-drawer-container class="mainapp" autosize>
  <mat-drawer
    #chatNavDrawer
    class="sidemain"
    mode="over"
    *ngIf="routing.isChat()"
  >
    <div class="blok">
      <app-chat-mobile-navigation></app-chat-mobile-navigation>
    </div>
  </mat-drawer>
  <mat-drawer #userNavDrawer class="sidemain" mode="over" position="end">
    <div class="blok">
      <app-mobile-user-navigation></app-mobile-user-navigation>
    </div>
  </mat-drawer>

  <div class="example-sidenav-content">
    <div class="contmobile">
      <app-mobile-menu-above></app-mobile-menu-above>
      <div
        class="contmid"
        [class.rounded]="routing.isNewsaudit() && routing.isMarket()"
      >
        <router-outlet></router-outlet>
      </div>
      <div class="contbot">
        <nav mat-tab-nav-bar *ngIf="routing.isFolio() || routing.isChat()">
          <a
            mat-tab-link
            routerLink="chat"
            routerLinkActive="active"
            [active]="routing.isChat()"
          >
            <mat-icon>chat_question</mat-icon>
          </a>
          <a
            mat-tab-link
            routerLink="news/portfolio/scanner"
            [queryParams]="{ tag: null, newsday: null }"
            queryParamsHandling="merge"
            routerLinkActive="active"
            [active]="routing.isScanner()"
          >
            <mat-icon>compass_calibration</mat-icon>
          </a>
          <a
            mat-tab-link
            routerLink="news/portfolio/audit"
            [queryParams]="{ tag: null, newsday: null }"
            queryParamsHandling="merge"
            routerLinkActive="active"
            [active]="routing.isNewsaudit()"
          >
            <mat-icon svgIcon="newspaper-variant"></mat-icon>
          </a>
        </nav>

        <nav mat-tab-nav-bar *ngIf="routing.isMarket()">
          <a
            mat-tab-link
            routerLink="chat"
            routerLinkActive="active"
            [active]="routing.isChat()"
          >
            <mat-icon>chat_question</mat-icon>
          </a>
          <a
            mat-tab-link
            routerLink="news/market/chart"
            [queryParams]="{ tag: null, newsday: null }"
            queryParamsHandling="merge"
            routerLinkActive="active"
            [active]="routing.isChart()"
          >
            <mat-icon>insert_chart</mat-icon>
          </a>
          <a
            mat-tab-link
            routerLink="news/market/audit"
            [queryParams]="{ tag: null, newsday: null }"
            queryParamsHandling="merge"
            routerLinkActive="active"
            [active]="routing.isNewsaudit()"
          >
            <mat-icon svgIcon="newspaper-variant"></mat-icon>
          </a>
        </nav>
      </div>
    </div>
  </div>
</mat-drawer-container>
