import {
  Component,
  Inject,
  OnInit,
  ViewEncapsulation,
  OnDestroy,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  MAT_BOTTOM_SHEET_DATA,
  MatBottomSheetRef,
} from '@angular/material/bottom-sheet';
import { allRoles } from 'src/app/cockpit/main-menu/menu-user-profile/user-role-options';
import { UserAccount } from 'src/models/user.types';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AuthService } from 'src/app/service/auth.service';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-mobile-account-setting',
  templateUrl: './mobile-account-setting.component.html',
  styleUrls: ['./mobile-account-setting.component.scss'],
})
export class MobileAccountSettingComponent implements OnInit, OnDestroy {
  rolesOption = allRoles;
  userInfo: UserAccount;
  userForm: FormGroup;
  private destroy$ = new Subject<void>();
  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: { userInfo: UserAccount },
    private router: Router,
    private bottomSheetRef: MatBottomSheetRef<MobileAccountSettingComponent>,

    private snackBar: MatSnackBar,
    private fb: FormBuilder,
    public auth: AuthService,
  ) {}

  ngOnInit(): void {
    this.userInfo = this.data.userInfo;
    this.userForm = this.fb.group({
      first_name: [this.userInfo.first_name],
      last_name: [this.userInfo.last_name],
      company: [this.userInfo.company],
      phone: [this.userInfo.phone],
      job_title: [this.userInfo.job_title],
      role: [this.userInfo.role],
    });
  }

  ngOnDestroy(): void {
    this.closeBottomSheet();
    this.destroy$.next();
    this.destroy$.complete();
  }

  saveUserSettings() {
    const accountData: Partial<UserAccount> = this.userForm.value;
    this.updateUserSettings(accountData).subscribe({
      next: () => {
        this.snackBar.open('User settings were updated successfully', 'OK', {
          duration: 5000,
        });
        this.closeBottomSheet();
      },
      error: () => {
        this.snackBar.open(`Error updating user settings`, 'OK', {
          duration: 5000,
        });
      },
    });
  }

  private updateUserSettings(
    userData: Partial<UserAccount>,
  ): Observable<UserAccount> {
    const userAccountInfo = this.userInfo;
    const updatedUserData = {
      ...userAccountInfo,
      ...userData,
    };
    return this.auth.updateAccount(updatedUserData).pipe(
      tap(() => {
        this.auth.updateAccountInfo(updatedUserData);
      }),
    );
  }

  closeBottomSheet(): void {
    this.bottomSheetRef.dismiss();
  }

  navigateChangePassword() {
    this.closeBottomSheet();
    this.router.navigate(['/password/change']);
  }
}
