<div class="mobile-chat__header">
  <mat-icon class="mobile-chat__header-icon" (click)="chatNavigationMenuOpen()"
    >menu</mat-icon
  >
  <div class="mobile-chat__header-title">
    <span>News Assistant</span>
    <span class="info-label">BETA</span>
  </div>

  <span class="mobile-chat__header-icon new" (click)="startNewChat()">
    <svg-icon src="assets/icon/jam_write.svg"></svg-icon>
  </span>
</div>
