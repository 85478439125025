import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AuthService } from 'src/app/service/auth.service';
import {
  MobileDrawerService,
  DrawerType,
} from '../sevices/mobile-drawer-service';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MobileAccountSettingComponent } from '../mobile-account-setting/mobile-account-setting.component';
import { UserAccount } from 'src/models/user.types';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

interface EventWeight {
  event_id: string;
  weight: number;
  polarity: number;
  weight_persistence: number;
}

interface Score {
  name: string;
  uid: string;
  event_weights?: EventWeight[];
  sentiment_weight: number;
  sentiment_type: 'pos' | 'neg' | 'neutral' | '';
}

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-mobile-user-navigation',
  templateUrl: './mobile-user-navigation.component.html',
  styleUrls: ['./mobile-user-navigation.component.scss'],
})
export class MobileUserNavigationComponent implements OnInit, OnDestroy {
  currentScore: Score;
  customScores: Score[] = [];
  yukkaScores: Score[] = [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  params: any;
  userAccountInfo: UserAccount;
  private destroy$ = new Subject<void>();
  private possibleScores = {
    sentimentAccess: 'sentiment',
    generalRiskAccess: 'general_risk',
    creditRiskAccess: 'credit_risk',
    esgRiskAccess: 'esg_risk',
    immediateRiskAccess: 'immediate_risk',
    esgAccess: 'esg',
    eb_simAccess: 'eb_sim',
    bbAccess: 'bb',
  };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private mobileDrawerService: MobileDrawerService,
    private auth: AuthService,
    public snackBar: MatSnackBar,
    private bottomSheet: MatBottomSheet,
  ) {
    this.route.queryParams.subscribe((params) => {
      this.params = { ...params };
    });
  }

  ngOnInit(): void {
    this.setAccountInfo();
    this.initializeScores();
    this.setCurrentScoreOnInit();
  }

  ngOnDestroy(): void {
    this.closeMobileUserDrawer();
    this.destroy$.next();
    this.destroy$.complete();
  }

  closeMobileUserDrawer(): void {
    this.mobileDrawerService.closeDrawer(DrawerType.USER);
  }

  openUserSettingsPopup(): void {
    this.bottomSheet.open(MobileAccountSettingComponent, {
      disableClose: false,
      autoFocus: false,
      panelClass: 'mobile-account-setting',
      data: {
        userInfo: this.userAccountInfo,
      },
    });
    this.closeMobileUserDrawer();
  }

  private initializeScores(): void {
    this.yukkaScores = Object.entries(this.possibleScores).reduce(
      (acc, [flag, scoreId]) => {
        if (this.auth.scorelabSettings[flag]) {
          const info = this.auth.getScoresInfo(scoreId);
          acc.push({
            name: 'name' in info ? info.name : '',
            uid: scoreId,
            event_weights: [],
            sentiment_type: '',
            sentiment_weight: null,
          });
        }
        return acc;
      },
      [],
    );

    if (this.auth.scorelabSettings.customScores) {
      this.customScores = [...this.auth.scorelabSettings.customScores];
    }
  }

  private setAccountInfo(): void {
    this.auth.accountInfo$
      .pipe(takeUntil(this.destroy$))
      .subscribe((accountInfo) => {
        this.userAccountInfo = accountInfo;
      });
  }

  private setCurrentScoreOnInit(): void {
    const defaultScoreId = this.auth.scorelabSettings.defaultScore;
    const defaultScoreInfo = this.auth.getScoreName();
    this.currentScore = this.yukkaScores.find(
      (item) => item.uid === defaultScoreId,
    ) ||
      this.customScores.find((item) => item.uid === defaultScoreId) || {
        name: defaultScoreInfo.name,
        uid: defaultScoreId,
        event_weights: [],
        sentiment_type: '',
        sentiment_weight: null,
      };
  }

  setCurrentScore(score: Score): void {
    this.currentScore = score;
    this.auth.scorelabSettings.defaultScore = score.uid;
    const newDefaultName = this.auth.getScoreName().full;
    const newCustomSettings = {
      ...this.auth.cockpitSettings,
      user_defaultScore: score.uid,
    };
    this.auth.cockpitSettings = newCustomSettings;

    this.auth.setCustomSettings(newCustomSettings).subscribe(() => {
      this.snackBar.open(`Default score set to: ${newDefaultName}.`, 'OK', {
        duration: 5000,
      });
    });
    this.updateQueryParams();
    this.closeMobileUserDrawer();
  }

  private updateQueryParams(): void {
    const sortParam = this.params.sort
      ? this.auth.scorelabSettings.defaultScore === 'sentiment'
        ? '-senti'
        : 'score'
      : undefined;

    this.router.navigate([], {
      queryParams: {
        update: Math.random(),
        sort: sortParam,
        focused_score: this.auth.scorelabSettings.defaultScore,
        score: null,
        time: this.params.score ? null : this.params.time,
      },
      queryParamsHandling: 'merge',
      replaceUrl: true,
    });
  }
}
