<div class="mobile-menu-above">
  <div class="mobile-menu-above__header">
    <div class="mobile-menu-above__header-logo">
      <img
        *ngIf="routing.theme !== 'light'"
        class="navlogo"
        src="assets/logo/{{ logo }}_menu.svg"
        alt="yukkalab"
        (click)="inLogo()"
      />
      <img
        *ngIf="routing.theme === 'light'"
        class="navlogo"
        src="assets/logo/{{ logo }}_menu_light.svg"
        alt="yukkalab"
        (click)="inLogo()"
      />
    </div>
    <div class="mobile-menu-above__header-user">
      <button
        mat-icon-button
        class="mobile-menu-above__header user__button"
        (click)="openUserSettings()"
      >
        <mat-icon>person</mat-icon>
      </button>
    </div>
  </div>
  <div class="mobile-menu-above__search" *ngIf="!routing.isChat()">
    <app-mobile-search-bar></app-mobile-search-bar>
  </div>
  <div class="mobile-menu-above__search" *ngIf="routing.isChat()">
    <app-chat-mobile-header></app-chat-mobile-header>
  </div>
</div>
