import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';

import { ChatNavigationService } from '../../services/chat-navigation-service/chat-navigation-service';
import { ChatDetailsResponse } from '../../interfaces/chart-details.interface';
import { combineLatest, Subject, Subscription } from 'rxjs';
import { ChatParentStateService } from '../../services/chat-parent-state.service';
import {
  DrawerType,
  MobileDrawerService,
} from 'src/app/project/mobile/sevices/mobile-drawer-service';

@Component({
  selector: 'app-chat-mobile-navigation',
  templateUrl: './chat-mobile-navigation.component.html',
  styleUrls: ['./chat-mobile-navigation.component.scss'],
})
export class ChatMobileNavigationComponent implements OnInit, OnDestroy {
  defaultTitle: string = 'New Chat';
  chats: ChatDetailsResponse[] = [];
  activeChatId: string | null = null;

  private chatListSubscription?: Subscription;
  private routeSubscription?: Subscription;
  private destroy$ = new Subject<void>();
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private chatNavigationService: ChatNavigationService,
    private mobileDrawerService: MobileDrawerService,
    private chatParentStateService: ChatParentStateService,
  ) {
    this.chatNavigationService.chatList$.subscribe({
      next: (data) => {
        this.chats = data;
      },
    });
    combineLatest([
      this.chatParentStateService.parentChatId$,
      this.chatParentStateService.parentChatTitle$,
    ])
      .pipe(
        takeUntil(this.destroy$),
        filter(([id, title]) => !!id && !!title),
      )
      .subscribe(([chatId, chatTitle]) => {
        this.chatNavigationService.updateChatTitleIfDefaultTitle(
          chatId,
          chatTitle,
        );
      });
  }
  ngOnInit(): void {
    this.getCharts();

    this.routeSubscription = this.route.queryParams
      .pipe(filter((params) => params['chatId']))
      .subscribe((params) => {
        this.activeChatId = params['chatId'];
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.routeSubscription?.unsubscribe();
    this.chatListSubscription?.unsubscribe();
  }

  getCharts(): void {
    this.chatNavigationService.fetchChats().subscribe({
      next: (data) => {
        this.chats = data;

        this.redirectToFirstChatOrStartNew();
      },
    });
  }

  redirectToFirstChatOrStartNew(): void {
    if (this.chats && this.chats.length > 0) {
      if (!this.activeChatId) {
        const firstChatId = this.chats[0].chat_id;
        this.router
          .navigate([], {
            relativeTo: this.route,
            queryParams: { chatId: firstChatId },
            queryParamsHandling: 'merge',
          })
          .then(() => {
            this.activeChatId = firstChatId;
          })
          .catch((err) => {
            console.error('Navigation error:', err);
          });
      }
    } else {
      this.startNewChat();
    }
  }

  closeMobileChatDrawer(): void {
    this.mobileDrawerService.closeDrawer(DrawerType.CHAT);
  }

  startNewChat(): void {
    this.chatNavigationService.startNewChat();
    this.closeMobileChatDrawer();
  }

  goToChatConversation(chat: ChatDetailsResponse): void {
    if (chat && chat.chat_id) {
      this.chatNavigationService.goToChat(chat.chat_id);
      this.closeMobileChatDrawer();
    } else {
      console.error('chat id is undefined or chat object is invalid', chat);
    }
  }
}
