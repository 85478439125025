import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export enum DrawerType {
  CHAT = 'chat',
  USER = 'user',
}

interface DrawerState {
  [DrawerType.CHAT]: boolean;
  [DrawerType.USER]: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class MobileDrawerService {
  private initialState: DrawerState = {
    [DrawerType.CHAT]: false,
    [DrawerType.USER]: false,
  };

  private drawerState = new BehaviorSubject<DrawerState>(this.initialState);
  drawerState$ = this.drawerState.asObservable();

  constructor() {}

  openDrawer(drawer: DrawerType) {
    this.drawerState.next({
      ...this.initialState,
      [drawer]: true,
    });
  }

  closeDrawer(drawer: DrawerType) {
    this.drawerState.next({
      ...this.drawerState.value,
      [drawer]: false,
    });
  }

  toggleDrawer(drawer: DrawerType) {
    const currentState = this.drawerState.value[drawer];
    if (currentState) {
      this.closeDrawer(drawer);
    } else {
      this.openDrawer(drawer);
    }
  }

  resetDrawerState() {
    this.drawerState.next(this.initialState);
  }
}
