/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { RoutingService } from 'src/app/service/routing.service';
import { AuthService } from 'src/app/service/auth.service';
import { ConfigService } from 'src/app/service/config.service';
import {
  DrawerType,
  MobileDrawerService,
} from '../sevices/mobile-drawer-service';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-mobile-menu-above',
  templateUrl: './mobile-menu-above.component.html',
  styleUrls: ['./mobile-menu-above.component.scss'],
})
export class MobileMenuAboveComponent {
  project: string;
  params: any;
  logo: string;

  /**
   * query parameters subscribe
   */
  constructor(
    private route: ActivatedRoute,
    public routing: RoutingService,
    private router: Router,
    public auth: AuthService,
    public config: ConfigService,
    private mobileDrawerService: MobileDrawerService,
  ) {
    this.project = this.config.appConfig.routing.project;
    this.logo = this.auth.featureFlags.logo
      ? this.auth.featureFlags.logo
      : this.project;
    this.route.queryParams.subscribe((params) => {
      this.params = params;
      if (window.location.hostname === 'swissre.yukkalab.com') {
        this.logo = 'swissre';
      }
    });
  }

  /**
   * Click logo redirect
   */
  inLogo() {
    if (this.routing.isWidget()) {
      if (window.location.hostname === 'localhost') {
        window.open('//localhost:4200' + this.router.url);
      } else {
        window.open('//' + window.location.hostname + this.router.url);
      }
    } else {
      this.router.navigate(['/'], {
        queryParams: {
          id: null,
          score: null,
          time: this.params.score ? null : this.params.time,
        },
        queryParamsHandling: 'merge',
        replaceUrl: false,
      });
    }
  }

  openUserSettings() {
    this.mobileDrawerService.openDrawer(DrawerType.USER);
  }
}
