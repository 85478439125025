/* eslint-disable @typescript-eslint/no-explicit-any */

import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpParams,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { Router } from '@angular/router';
import * as jwt_decode from 'jwt-decode';
import {
  Observable,
  throwError,
  of,
  interval,
  BehaviorSubject,
  forkJoin,
} from 'rxjs';
import { catchError, tap, shareReplay, map, take } from 'rxjs/operators';
import { RoutingService } from './routing.service';
import { ConfigService } from './config.service';
import { NgHttpCachingService } from 'ng-http-caching';
import * as hash from 'object-hash';
import * as Sentry from '@sentry/angular';
import { appVersion } from '../../environments/version';
import { ChatPayload } from '../project/llm/interfaces/chart-details.interface';
import { UserAccount } from 'src/models/user.types';
const hashOptions = {
  algorithm: 'md5',
  encoding: 'hex',
};

export interface ScoreInfo {
  name: string;
  full: string;
  short: string;
}

export interface ScoreInfoList {
  yukka: ScoreInfo[];
  custom: ScoreInfo[];
}

declare let pendo: any;

/**
 * This service manage all the user activity like login, report, annotation, portfolio, query etc...
 */
@Injectable()
export class AuthService {
  /**
   * encoded customer API jwt access token with user related data
   */
  token: string;

  /**
   * encoded jwt access token for all data related apis with user related data
   */
  tokenDataAccess: string;

  /**
   * JWT authorization passed
   */
  jwtAuthorized = false;

  /**
   * test url for pendo integration
   */
  // apiUrl = 'https://api.github.com/users/lechst';

  /**
   * this is a duplicate of the token
   */
  userdata: any;

  /**
   * all information about the user
   */
  private accountInfoSubject = new BehaviorSubject<any>(null);
  accountInfo$ = this.accountInfoSubject.asObservable();

  /**
   * list of articles previously read by the user
   */
  seenArticles: any;

  /**
   * all cockpit constants not set in feature flags
   */
  internalCockpitSettings = {
    maxTimeframe: 466,
    portfolioInvestLab: {
      name: 'InvestLab',
      content: [
        'stock_index:dax_30',
        'stock_index:ftse_250',
        'stock_index:nikkei_225',
        'stock_index:s_p_500',
        'stock_index:cac_40',
        'stock_index:smi',
        'stock_index:djia',
        'stock_index:hsi',
        'stock_index:stoxx_europe_600',
      ],
    },
  };

  /**
   * default feature flags
   */
  defaultFlags = {
    // STRING FEATURES
    logo: 'stage',
    theme: 'dark',
    defaultPortfolioView: 'scanner',
    defaultCollectionView: 'main',
    defaultMarketView: 'main',
    newsletterBanner: '',
    newsletterLogo: '',
    newsletterDisclaimer: '',
    newsletterEmail: '',
    // NUMBER FEATURES
    collectionsLimit: 15,
    queriesPerCollectionsLimit: 30,
    portfoliosLimit: 100,
    entitiesPerPortfoliosLimit: 10000,
    // BOOLEAN FEATURES
    activityLogging: true,
    annotationsEnabled: true,
    betaLanguages: false,
    flaggingEnabled: true,
    dismissingEnabled: true,
    copyingArticleLinkEnabled: true,
    bookmarkingEnabled: true,
    importExportBookmarks: false,
    reportingEnabled: true,
    gettingHighlightsEnabled: true,
    addQueries: true,
    editQueries: true,
    importQueries: true,
    exportQueries: true,
    linkQueries: true,
    importCollections: true,
    exportCollections: true,
    createCollections: true,
    addEntities: true,
    editEntities: true,
    importPortfolios: true,
    exportPortfolios: true,
    createPortfolios: true,
    showMenuLHS: true,
    showMenuRHS: true,
    showMenuTop: true,
    showEventAlerts: true,
    showSanctions: true,
    showFactTempFilter: true,
    showTopNews: false,
    showKeywordSearch: false,
    showDeltaAlerts: false,
    // top menu options
    showLogo: true,
    showNavigation: false,
    showSearchMenu: true,
    showFilter: true,
    showSearchBar: true,
    showSearchQuery: true,
    showTheme: true,
    showFullscreen: true,
    showHelp: true,
    // board options
    showHomepage: true,
    // news lab options
    showNewsLab: true,
    showPortfolio: true,
    showCollection: true,
    showMarket: false,
    // LLM Chat
    showChatLLM: true,
    // invest lab options
    showInvestLab: true,
    showSignals: true,
    showTrending: true,
    // network
    showTrackNetwork: false,
    // user lab options
    showUserLab: true,
    showNewsletter: true,
    showAlerts: true,
    showAlertsSetup: true,
    showFlagsSetup: true,
    showTutorial: true,
    showSettings: true,
    // menu account options
    showMenuAccount: true,
    showEmail: true,
    showFeedback: true,
    showTutorialFull: true,
    showAdmin: true,
    showStats: true,
    showPassword: true,
    showLogout: true,
    // expand button
    showExpand: true,
    // view options
    showViewOptions: true,
    showScannerView: true,
    showMainView: true,
    showChart: true,
    showNewsView: true,
    showParticipants: true,
    showBenchmark: true,
    showRadar: true,
    showNetwork: true,
    showNewsletterPreview: true,
    showNewsletterLivePreview: true,
    showNewsletterArticles: true,
    // sort options
    showSortOptions: true,
    showSortSentiment: true,
    showSortAlphabetic: true,
    showSortTrending: true,
    showSortVolumeCount: true,
    showSortVolumeRatio: true,
  };

  /**
   * default scorelab settings
   */
  defaultScorelabSettings = {
    sentimentAccess: true,
    generalRiskAccess: false,
    creditRiskAccess: false,
    esgRiskAccess: false,
    immediateRiskAccess: false,
    esgAccess: false,
    eb_simAccess: false,
    bbAccess: false,
    customScoreAccess: false,
    defaultScore: 'sentiment',
    customScores: [],
    defaultFlag: null,
    customFlags: [],
  };

  /**
   * default scorelab settings for admins
   */
  defaultAdminScorelabSettings = {
    sentimentAccess: true,
    generalRiskAccess: true,
    creditRiskAccess: true,
    esgRiskAccess: true,
    immediateRiskAccess: true,
    esgAccess: true,
    eb_simAccess: false,
    bbAccess: true,
    customScoreAccess: true,
    defaultScore: 'sentiment',
    customScores: [],
    defaultFlag: null,
    customFlags: [],
  };

  /**
   * cockpit settings (feature flags included if present in the backend)
   */
  cockpitSettings: any;

  /**
   * cockpit settings for scorelab's scores
   */
  scorelabSettings = Object.assign({}, this.defaultScorelabSettings);

  /**
   * show in newsfeed
   */
  showArticleSentiment = false;

  /**
   * user related feature flags
   */
  featureFlags = Object.assign({}, this.defaultFlags);

  /**
   * api url for the http request
   */
  authapi: string; // apis.yukkalab.com/customer

  /**
   * newsletter api url for the http request
   */
  newsletterAPI: string;

  /**
   * reporting service api url for the http request
   */
  reportingServiceAPI: string;

  /**
   * reporting service api url for the http request
   */
  alertingServiceAPI: string;

  /**
   * llm chat api url for the http request
   */
  apiurlChatLLM: string;
  /**
   * portfolio's data
   */
  folios: any;

  /**
   * caching portfolios
   */
  cachedPortfolios: any;

  /**
   * suppliers portfolio data
   */
  suppliersPortfolio: any;

  /**
   * active portfolio
   */
  folio: any;

  /**
   * check if portfolios were changed
   */
  portfoliosChange = true;

  /**
   * custom default portfolio for homepage
   */
  customDefaultPortfolio: any;

  /**
   * entities send as an array in request's body
   */
  payloadEntities = [];

  /**
   * active filter
   */
  filter: any;

  /**
   * custom default filter
   */
  defaultFilter: any;

  /**
   * list of all events (middle level) and sub-events
   */
  eventsList: any;

  /**
   * query's data
   */
  querys: any;

  /**
   * cached queries
   */
  cachedQueries: any;

  /**
   * cached collections
   */
  cachedCollections: any;

  /**
   * tracking change in one of the queries for caching
   */
  queriesChange = true;

  /**
   * tracking change in one of the collections for caching
   */
  collectionsChange = true;

  /**
   * active query
   */
  iquery: any;

  /**
   * query selected also a duplicate
   */
  query: any;

  /**
   * selected query to be used in the newsfeed
   */
  queryForNewsfeed: any;

  /**
   * selected potential named entity (PNE)
   */
  pne: any;

  /**
   * active search
   */
  search: any;

  /**
   * newsletter related parameters for newsfeed articles
   */
  newsletterParams = {};

  /**
   * newsfeed component instance reference
   */
  newsfeedRef: any;

  /**
   * whether warning flag is present or not
   */
  showWarningFlag = false;
  public showWarningFlag$ = new BehaviorSubject(false);

  /**
   * whether caution flag is present or not
   */
  showCautionFlag = false;
  public showCautionFlag$ = new BehaviorSubject(false);

  /**
   * type of focused score
   */
  scoreFocusedType = null;

  /**
   * focused score's color value
   */
  scoreFocusedColor = 'transparent';

  /**
   * focused score's timeframe
   */
  scoreFocusedTimeframe = null;

  /**
   * focused score's events time series
   */
  scoreFocusedEvents = null;

  /**
   * focused score's events time series filtered by temporality and factuality
   */
  scoreFocusedEventsAnalytics = null;

  /**
   * focused score's top events
   */
  scoreFocusedTopEvents = null;

  /**
   * suppliers view data
   */
  selectedSupplierScore = null;

  /**
   * newsfeed keywords
   */
  keywords = '';

  /**
   * subscribe every 15 minutes to check if the token is expired, if expired back to login,
   * validate it to get new token and save it in the session storage
   */
  constructor(
    private http: HttpClient,
    private router: Router,
    public routing: RoutingService,
    public config: ConfigService,
    private ngHttpCachingService: NgHttpCachingService,
  ) {
    this.portfoliosChange = true;
    this.queriesChange = true;
    this.collectionsChange = true;
    this.authapi = this.config.appConfig.urlCustomerApi;
    this.newsletterAPI = this.config.appConfig.urlNewsletterApi;
    this.reportingServiceAPI = this.config.appConfig.urlReportingServiceApi;
    this.alertingServiceAPI = this.config.appConfig.urlAlertingServiceApi;
    this.apiurlChatLLM = this.config.appConfig.urlChatLLMApi;

    interval(900000).subscribe(() => {
      if (!this.routing.isReader() && !this.routing.isStoryReader()) {
        const user = this.authToken();
        if (user) {
          const expire = new Date(user.exp * 1000);
          const now = new Date();
          if (expire < now) {
            this.router.navigate(['/login']);
          }
        } else {
          if (!this.routing.isLogin()) {
            this.router.navigate(['/login']);
          }
        }
        if (this.token) {
          this.http
            .post(
              this.authapi + '/v5/validate/jwt',
              {
                token: this.token,
              },
              {
                headers: new HttpHeaders().set(
                  'Content-Type',
                  'application/json',
                ),
                responseType: 'json',
              },
            )
            .subscribe(
              (result) => {
                const data: any = result;
                this.token = data.token;
                if (this.config.appConfig.setup.useStorage) {
                  localStorage.setItem('token', this.token);
                }
                this.tokenDataAccess = data.data_access_token;
                if (this.config.appConfig.setup.useStorage) {
                  localStorage.setItem('tokenDataAccess', this.tokenDataAccess);
                }
              },
              () => {
                this.router.navigate(['/login']);
              },
            );
        }
      }
    });
    window.addEventListener('storage', (event) => {
      const currentUser = event.newValue
        ? jwt_decode(event.newValue).email
        : null;
      const previousUser = event.oldValue
        ? jwt_decode(event.oldValue).email
        : null;
      if (
        event.key === 'token' &&
        (!currentUser ||
          (currentUser && previousUser && currentUser !== previousUser))
      ) {
        this.router.navigate(['/login']);
      }
    });
  }

  /**
   * HttpHeaders for all the request
   */
  getHeader() {
    let token = null;
    if (
      this.routing.isReader() ||
      this.routing.isStoryReader() ||
      this.routing.isWidget()
    ) {
      token = this.token;
    } else {
      token = localStorage.getItem('token');
    }
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
    });
  }

  // ERROR ---------------------------------------------------------------------

  /**
   * Console log error in case of fail request
   */
  private handleError(error: HttpErrorResponse) {
    let message = 'Something bad happened please try again later.';
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
      message = error.error.message;
    } else {
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`,
      );
      message = error.error;
      if (error.status === 401) {
        this.router.navigate(['/login']);
        message = 'Unauthorized';
      }
    }
    return throwError(message);
  }

  // FEATURE FLAGS ------------------------------------------------------------

  /**
   * loads the remote user-specific configuration data based on the token
   */
  loadFeatureFlags(token): Observable<any> {
    return forkJoin({
      cockpitSettings: this.getCustomSettings(token),
      accountInfo: this.getUserInfo(),
    }).pipe(
      tap((result) => {
        this.accountInfoSubject.next(
          JSON.parse(JSON.stringify(result['accountInfo'])),
        );
        const user = jwt_decode(this.token);
        const isAdmin =
          user?.roles?.includes('ADMIN') || user?.groups?.includes('ADMIN');
        const enableTrackNetworkView =
          user?.roles?.includes('ORG_SCH') || user?.groups?.includes('ORG_SCH');
        const enableTopNews =
          user?.roles?.includes('ORG_INT') || user?.groups?.includes('ORG_INT');
        if (
          Object.prototype.hasOwnProperty.call(
            result['cockpitSettings'],
            'settings',
          )
        ) {
          this.cockpitSettings = JSON.parse(
            JSON.stringify(result['cockpitSettings']['settings']),
          );
        } else {
          this.cockpitSettings = {};
        }
        Object.assign(
          this.featureFlags,
          this.defaultFlags,
          this.cockpitSettings,
        );
        if (enableTrackNetworkView) {
          this.featureFlags.showTrackNetwork = true;
        }
        if (enableTopNews) {
          this.featureFlags.showTopNews = true;
        }
        Object.assign(
          this.scorelabSettings,
          isAdmin
            ? this.defaultAdminScorelabSettings
            : this.defaultScorelabSettings,
        );
        if (isAdmin && this.cockpitSettings.betaLanguages !== false) {
          this.featureFlags.betaLanguages = true;
        }
        if (
          Object.prototype.hasOwnProperty.call(
            this.cockpitSettings,
            'customDefaultPortfolio',
          )
        ) {
          this.customDefaultPortfolio = JSON.parse(
            JSON.stringify(this.cockpitSettings.customDefaultPortfolio),
          );
        } else {
          this.customDefaultPortfolio = '';
        }
        if (
          Object.prototype.hasOwnProperty.call(
            this.cockpitSettings,
            'showArticleSentiment',
          )
        ) {
          this.showArticleSentiment = JSON.parse(
            JSON.stringify(this.cockpitSettings.showArticleSentiment),
          );
        }
        if (
          Object.prototype.hasOwnProperty.call(
            this.cockpitSettings,
            'user_customScores',
          )
        ) {
          Object.assign(this.scorelabSettings, {
            customScores: this.cockpitSettings.user_customScores,
          });
        }
        if (
          Object.prototype.hasOwnProperty.call(
            this.cockpitSettings,
            'defaultScore',
          )
        ) {
          if (!this.getScoresInfo(this.cockpitSettings.defaultScore)['name']) {
            this.cockpitSettings.defaultScore = 'sentiment';
          }
          Object.assign(this.scorelabSettings, {
            defaultScore: this.cockpitSettings.defaultScore,
          });
        }
        if (
          Object.prototype.hasOwnProperty.call(
            this.cockpitSettings,
            'user_defaultScore',
          )
        ) {
          if (
            !this.getScoresInfo(this.cockpitSettings.user_defaultScore)['name']
          ) {
            this.cockpitSettings.user_defaultScore = 'sentiment';
          }
          Object.assign(this.scorelabSettings, {
            defaultScore: this.cockpitSettings.user_defaultScore,
          });
        }
        if (
          Object.prototype.hasOwnProperty.call(
            this.cockpitSettings,
            'user_defaultFlag',
          )
        ) {
          Object.assign(this.scorelabSettings, {
            defaultFlag: this.cockpitSettings.user_defaultFlag,
          });
        }
        if (
          Object.prototype.hasOwnProperty.call(
            this.cockpitSettings,
            'user_customFlags',
          )
        ) {
          Object.assign(this.scorelabSettings, {
            customFlags: this.cockpitSettings.user_customFlags,
          });
        }
        if (
          Object.prototype.hasOwnProperty.call(
            this.cockpitSettings,
            'sentimentAccess',
          )
        ) {
          Object.assign(this.scorelabSettings, {
            sentimentAccess: this.cockpitSettings.sentimentAccess,
          });
        }
        if (
          Object.prototype.hasOwnProperty.call(
            this.cockpitSettings,
            'generalRiskAccess',
          )
        ) {
          Object.assign(this.scorelabSettings, {
            generalRiskAccess: this.cockpitSettings.generalRiskAccess,
          });
        }
        if (
          Object.prototype.hasOwnProperty.call(
            this.cockpitSettings,
            'creditRiskAccess',
          )
        ) {
          Object.assign(this.scorelabSettings, {
            creditRiskAccess: this.cockpitSettings.creditRiskAccess,
          });
        }
        if (
          Object.prototype.hasOwnProperty.call(
            this.cockpitSettings,
            'esgRiskAccess',
          )
        ) {
          Object.assign(this.scorelabSettings, {
            esgRiskAccess: this.cockpitSettings.esgRiskAccess,
          });
        }
        if (
          Object.prototype.hasOwnProperty.call(
            this.cockpitSettings,
            'immediateRiskAccess',
          )
        ) {
          Object.assign(this.scorelabSettings, {
            immediateRiskAccess: this.cockpitSettings.immediateRiskAccess,
          });
        }
        if (
          Object.prototype.hasOwnProperty.call(
            this.cockpitSettings,
            'esgAccess',
          )
        ) {
          Object.assign(this.scorelabSettings, {
            esgAccess: this.cockpitSettings.esgAccess,
          });
        }
        if (
          Object.prototype.hasOwnProperty.call(
            this.cockpitSettings,
            'eb_simAccess',
          )
        ) {
          Object.assign(this.scorelabSettings, {
            eb_simAccess: this.cockpitSettings.eb_simAccess,
          });
        }
        if (
          Object.prototype.hasOwnProperty.call(this.cockpitSettings, 'bbAccess')
        ) {
          Object.assign(this.scorelabSettings, {
            bbAccess: this.cockpitSettings.bbAccess,
          });
        }
        if (
          Object.prototype.hasOwnProperty.call(
            this.cockpitSettings,
            'bbmAccess',
          )
        ) {
          Object.assign(this.scorelabSettings, {
            bbAccess: this.cockpitSettings.bbmAccess,
          });
        }
        if (
          Object.prototype.hasOwnProperty.call(
            this.cockpitSettings,
            'customScoreAccess',
          )
        ) {
          Object.assign(this.scorelabSettings, {
            customScoreAccess: this.cockpitSettings.customScoreAccess,
          });
        }
        if (
          this.scorelabSettings.defaultScore === 'sentiment' &&
          this.scorelabSettings.sentimentAccess === false
        ) {
          this.scorelabSettings.defaultScore = 'general_risk';
        }
        if (
          this.scorelabSettings.defaultScore === 'general_risk' &&
          this.scorelabSettings.generalRiskAccess === false
        ) {
          if (this.scorelabSettings.creditRiskAccess === true) {
            this.scorelabSettings.defaultScore = 'credit_risk';
          } else if (this.scorelabSettings.esgRiskAccess === true) {
            this.scorelabSettings.defaultScore = 'esg_risk';
          } else if (this.scorelabSettings.immediateRiskAccess === true) {
            this.scorelabSettings.defaultScore = 'immediate_risk';
          } else if (this.scorelabSettings.esgAccess === true) {
            this.scorelabSettings.defaultScore = 'esg';
          } else if (this.scorelabSettings.eb_simAccess === true) {
            this.scorelabSettings.defaultScore = 'eb_sim';
          } else if (this.scorelabSettings.bbAccess === true) {
            this.scorelabSettings.defaultScore = 'bb';
          } else if (this.scorelabSettings.customScoreAccess === true) {
            this.scorelabSettings.defaultScore = '';
          }
        } else if (
          this.scorelabSettings.defaultScore === 'credit_risk' &&
          this.scorelabSettings.creditRiskAccess === false
        ) {
          if (this.scorelabSettings.generalRiskAccess === true) {
            this.scorelabSettings.defaultScore = 'general_risk';
          } else if (this.scorelabSettings.esgRiskAccess === true) {
            this.scorelabSettings.defaultScore = 'esg_risk';
          } else if (this.scorelabSettings.immediateRiskAccess === true) {
            this.scorelabSettings.defaultScore = 'immediate_risk';
          } else if (this.scorelabSettings.esgAccess === true) {
            this.scorelabSettings.defaultScore = 'esg';
          } else if (this.scorelabSettings.eb_simAccess === true) {
            this.scorelabSettings.defaultScore = 'eb_sim';
          } else if (this.scorelabSettings.bbAccess === true) {
            this.scorelabSettings.defaultScore = 'bb';
          } else if (this.scorelabSettings.customScoreAccess === true) {
            this.scorelabSettings.defaultScore = '';
          }
        } else if (
          this.scorelabSettings.defaultScore === 'esg_risk' &&
          this.scorelabSettings.esgRiskAccess === false
        ) {
          if (this.scorelabSettings.generalRiskAccess === true) {
            this.scorelabSettings.defaultScore = 'general_risk';
          } else if (this.scorelabSettings.creditRiskAccess === true) {
            this.scorelabSettings.defaultScore = 'credit_risk';
          } else if (this.scorelabSettings.immediateRiskAccess === true) {
            this.scorelabSettings.defaultScore = 'immediate_risk';
          } else if (this.scorelabSettings.esgAccess === true) {
            this.scorelabSettings.defaultScore = 'esg';
          } else if (this.scorelabSettings.eb_simAccess === true) {
            this.scorelabSettings.defaultScore = 'eb_sim';
          } else if (this.scorelabSettings.bbAccess === true) {
            this.scorelabSettings.defaultScore = 'bb';
          } else if (this.scorelabSettings.customScoreAccess === true) {
            this.scorelabSettings.defaultScore = '';
          }
        } else if (
          this.scorelabSettings.defaultScore === 'immediate_risk' &&
          this.scorelabSettings.immediateRiskAccess === false
        ) {
          if (this.scorelabSettings.generalRiskAccess === true) {
            this.scorelabSettings.defaultScore = 'general_risk';
          } else if (this.scorelabSettings.creditRiskAccess === true) {
            this.scorelabSettings.defaultScore = 'credit_risk';
          } else if (this.scorelabSettings.esgRiskAccess === true) {
            this.scorelabSettings.defaultScore = 'esg_risk';
          } else if (this.scorelabSettings.esgAccess === true) {
            this.scorelabSettings.defaultScore = 'esg';
          } else if (this.scorelabSettings.eb_simAccess === true) {
            this.scorelabSettings.defaultScore = 'eb_sim';
          } else if (this.scorelabSettings.bbAccess === true) {
            this.scorelabSettings.defaultScore = 'bb';
          } else if (this.scorelabSettings.customScoreAccess === true) {
            this.scorelabSettings.defaultScore = '';
          }
        } else if (
          this.scorelabSettings.defaultScore === 'esg' &&
          this.scorelabSettings.esgAccess === false
        ) {
          if (this.scorelabSettings.generalRiskAccess === true) {
            this.scorelabSettings.defaultScore = 'general_risk';
          } else if (this.scorelabSettings.creditRiskAccess === true) {
            this.scorelabSettings.defaultScore = 'credit_risk';
          } else if (this.scorelabSettings.esgRiskAccess === true) {
            this.scorelabSettings.defaultScore = 'esg_risk';
          } else if (this.scorelabSettings.eb_simAccess === true) {
            this.scorelabSettings.defaultScore = 'eb_sim';
          } else if (this.scorelabSettings.immediateRiskAccess === true) {
            this.scorelabSettings.defaultScore = 'immediate_risk';
          } else if (this.scorelabSettings.bbAccess === true) {
            this.scorelabSettings.defaultScore = 'bb';
          } else if (this.scorelabSettings.customScoreAccess === true) {
            this.scorelabSettings.defaultScore = '';
          }
        } else if (
          this.scorelabSettings.defaultScore === 'eb_sim' &&
          this.scorelabSettings.eb_simAccess === false
        ) {
          if (this.scorelabSettings.generalRiskAccess === true) {
            this.scorelabSettings.defaultScore = 'general_risk';
          } else if (this.scorelabSettings.creditRiskAccess === true) {
            this.scorelabSettings.defaultScore = 'credit_risk';
          } else if (this.scorelabSettings.esgRiskAccess === true) {
            this.scorelabSettings.defaultScore = 'esg_risk';
          } else if (this.scorelabSettings.esgAccess === true) {
            this.scorelabSettings.defaultScore = 'esg';
          } else if (this.scorelabSettings.immediateRiskAccess === true) {
            this.scorelabSettings.defaultScore = 'immediate_risk';
          } else if (this.scorelabSettings.bbAccess === true) {
            this.scorelabSettings.defaultScore = 'bb';
          } else if (this.scorelabSettings.customScoreAccess === true) {
            this.scorelabSettings.defaultScore = '';
          }
        } else if (
          this.scorelabSettings.defaultScore === 'eb_sim_risk' &&
          this.scorelabSettings.eb_simAccess === false
        ) {
          if (this.scorelabSettings.generalRiskAccess === true) {
            this.scorelabSettings.defaultScore = 'general_risk';
          } else if (this.scorelabSettings.creditRiskAccess === true) {
            this.scorelabSettings.defaultScore = 'credit_risk';
          } else if (this.scorelabSettings.esgRiskAccess === true) {
            this.scorelabSettings.defaultScore = 'esg_risk';
          } else if (this.scorelabSettings.esgAccess === true) {
            this.scorelabSettings.defaultScore = 'esg';
          } else if (this.scorelabSettings.immediateRiskAccess === true) {
            this.scorelabSettings.defaultScore = 'immediate_risk';
          } else if (this.scorelabSettings.bbAccess === true) {
            this.scorelabSettings.defaultScore = 'bb';
          } else if (this.scorelabSettings.customScoreAccess === true) {
            this.scorelabSettings.defaultScore = '';
          }
        } else if (
          this.scorelabSettings.defaultScore === 'bb' &&
          this.scorelabSettings.bbAccess === false
        ) {
          if (this.scorelabSettings.generalRiskAccess === true) {
            this.scorelabSettings.defaultScore = 'general_risk';
          } else if (this.scorelabSettings.creditRiskAccess === true) {
            this.scorelabSettings.defaultScore = 'credit_risk';
          } else if (this.scorelabSettings.esgRiskAccess === true) {
            this.scorelabSettings.defaultScore = 'esg_risk';
          } else if (this.scorelabSettings.immediateRiskAccess === true) {
            this.scorelabSettings.defaultScore = 'immediate_risk';
          } else if (this.scorelabSettings.esgAccess === true) {
            this.scorelabSettings.defaultScore = 'esg';
          } else if (this.scorelabSettings.eb_simAccess === true) {
            this.scorelabSettings.defaultScore = 'eb_sim';
          } else if (this.scorelabSettings.customScoreAccess === true) {
            this.scorelabSettings.defaultScore = '';
          }
        } else if (
          this.scorelabSettings.defaultScore !== 'sentiment' &&
          this.scorelabSettings.defaultScore !== 'general_risk' &&
          this.scorelabSettings.defaultScore !== 'credit_risk' &&
          this.scorelabSettings.defaultScore !== 'esg_risk' &&
          this.scorelabSettings.defaultScore !== 'immediate_risk' &&
          this.scorelabSettings.defaultScore !== 'esg' &&
          this.scorelabSettings.defaultScore !== 'eb_sim' &&
          this.scorelabSettings.defaultScore !== 'eb_sim_risk' &&
          this.scorelabSettings.defaultScore !== 'bb' &&
          this.scorelabSettings.customScoreAccess === false
        ) {
          if (this.scorelabSettings.generalRiskAccess === true) {
            this.scorelabSettings.defaultScore = 'general_risk';
          } else if (this.scorelabSettings.creditRiskAccess === true) {
            this.scorelabSettings.defaultScore = 'credit_risk';
          } else if (this.scorelabSettings.esgRiskAccess === true) {
            this.scorelabSettings.defaultScore = 'esg_risk';
          } else if (this.scorelabSettings.immediateRiskAccess === true) {
            this.scorelabSettings.defaultScore = 'immediate_risk';
          } else if (this.scorelabSettings.esgAccess === true) {
            this.scorelabSettings.defaultScore = 'esg';
          } else if (this.scorelabSettings.eb_simAccess === true) {
            this.scorelabSettings.defaultScore = 'eb_sim';
          } else if (this.scorelabSettings.bbAccess === true) {
            this.scorelabSettings.defaultScore = 'bb';
          } else {
            this.scorelabSettings.defaultScore = '';
          }
        }
        if (window.location.hostname === 'swissre.yukkalab.com') {
          this.featureFlags.logo = 'swissre';
        }
        let theme;
        if (this.config.appConfig.setup.useStorage) {
          theme = localStorage.getItem('theme');
        }
        if (!theme) {
          this.routing.inTheme(this.featureFlags.theme);
        }

        // Pendo integration

        let impersonate;
        if (this.config.appConfig.setup.useStorage) {
          impersonate = localStorage.getItem('impersonate');
        }
        if (
          user &&
          user.email &&
          !(impersonate === user.email) &&
          !this.config.appConfig.setup.noTracking &&
          !(
            Object.prototype.hasOwnProperty.call(user, 'roles') &&
            user.roles.includes('NO_TRACKING')
          ) &&
          !(
            Object.prototype.hasOwnProperty.call(user, 'groups') &&
            user.groups.includes('NO_TRACKING')
          )
        ) {
          forkJoin({
            scoreAlerts: this.getAlerts('score'),
            eventAlerts: this.getAlerts('event'),
            newsletters: this.allNewsletter(),
          }).subscribe((res) => {
            const noScoreAlerts = res['scoreAlerts'].hits.filter(
              (e) => e.active,
            ).length;
            const noEventAlerts = res['eventAlerts'].hits.filter(
              (e) => e.active,
            ).length;
            const noNewsletters = res['newsletters'].filter(
              (e) => e.active,
            ).length;
            const defaultScore = this.scorelabSettings.defaultScore;
            const visitorId = hash(user.email, hashOptions);
            let accountId = 'VISITOR-ACCOUNT-undefined';
            if (
              Object.prototype.hasOwnProperty.call(user, 'roles') &&
              user.roles.some((role) => role.includes('ORG'))
            ) {
              accountId = user.roles
                .find((role) => role.includes('ORG'))
                .split('_')[1];
            }
            if (
              Object.prototype.hasOwnProperty.call(user, 'groups') &&
              user.groups.some((group) => group.includes('ORG'))
            ) {
              accountId = user.groups
                .find((group) => group.includes('ORG'))
                .split('_')[1];
            }
            let pendoRole = null;
            if (
              Object.prototype.hasOwnProperty.call(user, 'roles') &&
              user.roles.includes('ADMIN')
            ) {
              pendoRole = 'ADMIN';
            }
            if (
              Object.prototype.hasOwnProperty.call(user, 'groups') &&
              user.groups.includes('ADMIN')
            ) {
              pendoRole = 'ADMIN';
            }
            this.accountInfo$.pipe(take(1)).subscribe((accountInfo) => {
              if (pendoRole) {
                pendo.initialize({
                  visitor: {
                    id: visitorId,
                    role: pendoRole,
                    SCORE_ALERT_QTY: noScoreAlerts,
                    EVENT_ALERT_QTY: noEventAlerts,
                    NEWSLETTER_QTY: noNewsletters,
                    SCORE_TYPE: defaultScore,
                    CAMPAIGN_ID: accountInfo.campaign_id,
                  },
                  account: {
                    id: accountId,
                  },
                });
              } else {
                pendo.initialize({
                  visitor: {
                    id: visitorId,
                    SCORE_ALERT_QTY: noScoreAlerts,
                    EVENT_ALERT_QTY: noEventAlerts,
                    NEWSLETTER_QTY: noNewsletters,
                    SCORE_TYPE: defaultScore,
                    CAMPAIGN_ID: accountInfo.campaign_id,
                  },
                  account: {
                    id: accountId,
                  },
                });
              }
            });
          });
        }

        // Sentry integration

        if (
          user &&
          user.email &&
          !this.config.appConfig.setup.noTracking &&
          !(
            Object.prototype.hasOwnProperty.call(user, 'roles') &&
            (user.roles.includes('NO_TRACKING') ||
              !user.roles.some((role) => role.includes('ORG')))
          ) &&
          !(
            Object.prototype.hasOwnProperty.call(user, 'groups') &&
            (user.groups.includes('NO_TRACKING') ||
              !user.groups.some((group) => group.includes('ORG')))
          )
        ) {
          if (!Sentry.getCurrentHub().getClient()) {
            try {
              Sentry.init({
                dsn: this.config.appConfig.urlSentry,
                environment:
                  this.config.appConfig.environment +
                  ' - ' +
                  this.config.appConfig.routing.project,
                release: appVersion + ' - ' + this.config.appConfig.version,
                integrations: [
                  Sentry.browserTracingIntegration(),
                  Sentry.replayIntegration(),
                ],
                // Tracing
                tracesSampleRate: 1.0, //  Capture 100% of the transactions
                // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
                tracePropagationTargets: [
                  'localhost',
                  /^https:\/\/yourserver\.io\/api/,
                ],
                // Session Replay
                replaysSessionSampleRate: this.config.appConfig.production
                  ? 1.0
                  : 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
                replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
              });
            } catch (error) {
              console.error('Error initializing Sentry:', error);
            }
          }
        }
      }),
      catchError((error) => this.handleError(error)),
    );
  }

  // TRACKING USER INTERACTIONS WITH COCKPIT

  /**
   * track users login sessions
   */
  trackLoginSession(isImpersonated): Observable<any> {
    const url = isImpersonated
      ? '/v1/cockpit_sessions/start?impersonated=true'
      : '/v1/cockpit_sessions/start';
    return this.http
      .get(this.authapi + '' + url, {
        headers: this.getHeader(),
      })
      .pipe(catchError((error) => this.handleError(error)));
  }

  // CUSTOM COCKPIT SETTINGS

  /**
   * get custom_settings
   */
  getCustomSettings(token): Observable<any> {
    return this.http
      .get(this.authapi + '/v1/cockpit_settings', {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        }),
      })
      .pipe(catchError((error) => this.handleError(error)));
  }

  /**
   * post custom_settings
   */
  setCustomSettings(content): Observable<any> {
    const settingsObj = {
      settings: content,
    };
    return this.http
      .post(this.authapi + '/v1/cockpit_settings', settingsObj, {
        headers: this.getHeader(),
      })
      .pipe(catchError((error) => this.handleError(error)));
  }

  /**
   * update customer account
   */

  updateAccount(account: UserAccount): Observable<any> {
    return this.http.post(this.authapi + '/v5/account', account, {
      headers: this.getHeader(),
    });
  }
  // CUSTOM USER SETTINGS

  /**
   * information about user
   */
  getUserInfo(): Observable<any> {
    return this.http
      .get(this.authapi + '/v5/account', {
        headers: this.getHeader(),
      })
      .pipe(catchError((error) => this.handleError(error)));
  }

  updateAccountInfo(accountInfo: any) {
    this.accountInfoSubject.next(accountInfo);
  }
  /**
   * get user settings based on a key
   */
  getUserSettings(key): Observable<any> {
    return this.http
      .get(this.authapi + '/v1/settings/' + key, {
        headers: this.getHeader(),
      })
      .pipe(catchError((error) => this.handleError(error)));
  }

  /**
   * post user settings as a key - value pair
   */
  setUserSettings(key, value): Observable<any> {
    const settingsObj = {
      key: key,
      entry: value,
    };
    return this.http
      .post(this.authapi + '/v1/settings', settingsObj, {
        headers: this.getHeader(),
        responseType: 'text' as const,
      })
      .pipe(catchError((error) => this.handleError(error)));
  }

  /**
   * delete user settings based on a key
   */
  deleteUserSettings(key): Observable<any> {
    return this.http
      .delete(this.authapi + '/v1/settings/' + key, {
        headers: this.getHeader(),
      })
      .pipe(catchError((error) => this.handleError(error)));
  }

  /**
   * getting current default yukka and custom scores names to be displayed in the cockpit
   */
  getScoreName() {
    if (this.scorelabSettings && this.scorelabSettings.defaultScore) {
      const id = this.scorelabSettings.defaultScore;
      if (id === 'sentiment') {
        return {
          name: 'Sentiment',
          full: 'Sentiment Score',
          short: 'Sent',
        };
      } else if (id === 'general_risk') {
        return {
          name: 'General Risk',
          full: 'General Risk Score',
          short: 'GR',
        };
      } else if (id === 'credit_risk') {
        return {
          name: 'Credit Risk',
          full: 'Credit Risk Score',
          short: 'CR',
        };
      } else if (id === 'esg_risk') {
        return {
          name: 'ESG Risk',
          full: 'ESG Risk Score',
          short: 'ESGR',
        };
      } else if (id === 'immediate_risk') {
        return {
          name: 'Immediate Risk',
          full: 'Immediate Risk Score',
          short: 'IR',
        };
      } else if (id === 'esg') {
        return {
          name: 'ESG',
          full: 'ESG Score',
          short: 'ESG',
        };
      } else if (id === 'eb_sim') {
        return {
          name: 'EB-SIM',
          full: 'EB-SIM Score',
          short: 'EB-SIM',
        };
      } else if (id === 'eb_sim_risk') {
        return {
          name: 'EB-SIM Risk',
          full: 'EB-SIM Risk Score',
          short: 'EB-SIMR',
        };
      } else if (id === 'bb') {
        return {
          name: 'Bull-Bear',
          full: 'Bull-Bear Score',
          short: 'BB',
        };
      } else if (id) {
        if (
          this.scorelabSettings.customScores &&
          this.scorelabSettings.customScores.length > 0
        ) {
          const name = this.scorelabSettings.customScores.filter((score) => {
            return score.uid === id;
          })[0]?.name;
          if (name) {
            return {
              name: name,
              full: name + ' Custom Score',
              short: name.substring(0, 5) + '...',
            };
          } else {
            return {
              name: '',
              full: 'Score',
              short: 'Score',
            };
          }
        } else {
          return {
            name: '',
            full: 'Score',
            short: 'Score',
          };
        }
      } else {
        return {
          name: '',
          full: 'Score',
          short: 'Score',
        };
      }
    } else {
      return {
        name: '',
        full: 'Score',
        short: 'Score',
      };
    }
  }

  /**
   * getting all yukka and custom scores ids and names to be displayed in the cockpit
   */
  getScoresInfo(id?: string): ScoreInfo | ScoreInfoList {
    if (id) {
      if (id === 'volume') {
        return {
          name: 'Volume',
          full: 'Volume Count',
          short: 'Vol',
        };
      } else if (id === 'sentiment') {
        return {
          name: 'Sentiment',
          full: 'Sentiment Score',
          short: 'Sent',
        };
      } else if (id === 'general_risk') {
        return {
          name: 'General Risk',
          full: 'General Risk Score',
          short: 'GR',
        };
      } else if (id === 'credit_risk') {
        return {
          name: 'Credit Risk',
          full: 'Credit Risk Score',
          short: 'CR',
        };
      } else if (id === 'credit_watch') {
        return {
          name: 'Credit Risk',
          full: 'Credit Risk Score',
          short: 'CR',
        };
      } else if (id === 'esg_risk') {
        return {
          name: 'ESG Risk',
          full: 'ESG Risk Score',
          short: 'ESGR',
        };
      } else if (id === 'immediate_risk') {
        return {
          name: 'Immediate Risk',
          full: 'Immediate Risk Score',
          short: 'IR',
        };
      } else if (id === 'esg') {
        return {
          name: 'ESG',
          full: 'ESG Score',
          short: 'ESG',
        };
      } else if (id === 'e_standard') {
        return {
          name: 'E Standard',
          full: 'E StandardScore',
          short: 'E',
        };
      } else if (id === 's_standard') {
        return {
          name: 'S Standard',
          full: 'S Standard Score',
          short: 'S',
        };
      } else if (id === 'g_standard') {
        return {
          name: 'G Standard',
          full: 'G Standard Score',
          short: 'G',
        };
      } else if (id === 'eb_sim') {
        return {
          name: 'EB-SIM',
          full: 'EB-SIM Score',
          short: 'EB-SIM',
        };
      } else if (id === 'eb_sim_risk') {
        return {
          name: 'EB-SIM Risk',
          full: 'EB-SIM Risk Score',
          short: 'EB-SIM R',
        };
      } else if (id === 'bb') {
        return {
          name: 'Bull-Bear',
          full: 'Bull-Bear Score',
          short: 'BB',
        };
      } else {
        if (
          this.scorelabSettings.customScores &&
          this.scorelabSettings.customScores.length > 0
        ) {
          const name = this.scorelabSettings.customScores.filter((score) => {
            return score.uid === id;
          })[0]?.name;
          if (name) {
            return {
              name: name,
              full: name + ' Custom Score',
              short: name.substring(0, 5) + '...',
            };
          } else {
            return {
              name: '',
              full: 'Score',
              short: 'Score',
            };
          }
        } else {
          return {
            name: '',
            full: 'Score',
            short: 'Score',
          };
        }
      }
    } else {
      const scoresInfo = {
        yukka: [],
        custom: [],
      };
      if (this.scorelabSettings.sentimentAccess) {
        scoresInfo.yukka.push({
          id: 'sentiment',
          name: 'Sentiment',
          full: 'Sentiment Score',
          short: 'Sent',
        });
      }
      if (this.scorelabSettings.generalRiskAccess) {
        scoresInfo.yukka.push({
          id: 'general_risk',
          name: 'General Risk',
          full: 'General Risk Score',
          short: 'GR',
        });
      }
      if (this.scorelabSettings.creditRiskAccess) {
        scoresInfo.yukka.push({
          id: 'credit_risk',
          name: 'Credit Risk',
          full: 'Credit Risk Score',
          short: 'CR',
        });
      }
      if (this.scorelabSettings.esgRiskAccess) {
        scoresInfo.yukka.push({
          id: 'esg_risk',
          name: 'ESG Risk',
          full: 'ESG Risk Score',
          short: 'ESGR',
        });
      }
      if (this.scorelabSettings.immediateRiskAccess) {
        scoresInfo.yukka.push({
          id: 'immediate_risk',
          name: 'Immediate Risk',
          full: 'Immediate Risk Score',
          short: 'IR',
        });
      }
      if (this.scorelabSettings.esgAccess) {
        scoresInfo.yukka.push({
          id: 'esg',
          name: 'ESG',
          full: 'ESG Score',
          short: 'ESG',
        });
      }
      if (this.scorelabSettings.eb_simAccess) {
        scoresInfo.yukka.push({
          id: 'eb_sim',
          name: 'EB-SIM',
          full: 'EB-SIM Score',
          short: 'EB-SIM',
        });
        scoresInfo.yukka.push({
          id: 'eb_sim_risk',
          name: 'EB-SIM Risk',
          full: 'EB-SIM Risk Score',
          short: 'EB-SIM R',
        });
      }
      if (this.scorelabSettings.bbAccess) {
        scoresInfo.yukka.push({
          id: 'bb',
          name: 'Bull-Bear',
          full: 'Bull-Bear Score',
          short: 'BB',
        });
      }
      if (
        this.scorelabSettings.customScoreAccess &&
        this.scorelabSettings.customScores &&
        this.scorelabSettings.customScores.length > 0
      ) {
        this.scorelabSettings.customScores.forEach((score) => {
          const name = score.name;
          scoresInfo.custom.push({
            id: score.uid,
            name: name,
            full: name + ' Custom Score',
            short: name.substring(0, 5) + '...',
          });
        });
      }
      return scoresInfo;
    }
  }

  /**
   * getting current default yukka and custom scores sentiment type (pos or neg)
   */
  getScoreType(id?) {
    if (id) {
      if (this.scorelabSettings && this.scorelabSettings.defaultScore) {
        if (id === 'sentiment') {
          return 'pos';
        } else if (id === 'general_risk') {
          return 'neg';
        } else if (id === 'credit_risk') {
          return 'neg';
        } else if (id === 'esg_risk') {
          return 'neg';
        } else if (id === 'immediate_risk') {
          return 'neg';
        } else if (id === 'esg') {
          return 'pos';
        } else if (id === 'eb_sim') {
          return 'pos';
        } else if (id === 'eb_sim_risk') {
          return 'neg';
        } else if (id === 'bb') {
          return 'neg';
        } else if (id) {
          if (
            this.scorelabSettings.customScores &&
            this.scorelabSettings.customScores.length > 0
          ) {
            const type = this.scorelabSettings.customScores.filter((score) => {
              return score.uid === id;
            })[0].sentiment_type;
            if (type) {
              return type;
            } else {
              return '';
            }
          } else {
            return '';
          }
        } else {
          return '';
        }
      } else {
        return '';
      }
    } else {
      if (this.scorelabSettings && this.scorelabSettings.defaultScore) {
        const id2 = this.scorelabSettings.defaultScore;
        if (id2 === 'sentiment') {
          return 'pos';
        } else if (id2 === 'general_risk') {
          return 'neg';
        } else if (id2 === 'credit_risk') {
          return 'neg';
        } else if (id2 === 'esg_risk') {
          return 'neg';
        } else if (id2 === 'immediate_risk') {
          return 'neg';
        } else if (id2 === 'esg') {
          return 'pos';
        } else if (id2 === 'eb_sim') {
          return 'pos';
        } else if (id2 === 'eb_sim_risk') {
          return 'neg';
        } else if (id2 === 'bb') {
          return 'neg';
        } else if (id2) {
          if (
            this.scorelabSettings.customScores &&
            this.scorelabSettings.customScores.length > 0
          ) {
            const type = this.scorelabSettings.customScores.filter((score) => {
              return score.uid === id2;
            })[0].sentiment_type;
            if (type) {
              return type;
            } else {
              return '';
            }
          } else {
            return '';
          }
        } else {
          return '';
        }
      } else {
        return '';
      }
    }
  }

  // AUTH ---------------------------------------------------------------------

  /**
   * autentification of the user login
   */
  authLogin(email, password): Observable<any> {
    return this.http
      .post(
        this.authapi + '/v5/authenticate',
        {
          email: email,
          password: password,
        },
        {
          headers: new HttpHeaders().set('Content-Type', 'application/json'),
          responseType: 'json',
        },
      )
      .pipe(
        tap((result) => {
          if (!result['two_factor_auth']) {
            const data: any = result;
            this.token = data.token;
            const user = jwt_decode(data.token);
            if (this.config.appConfig.setup.useStorage) {
              localStorage.setItem('token', this.token);
            }
            this.tokenDataAccess = data.data_access_token;
            if (this.config.appConfig.setup.useStorage) {
              localStorage.setItem('tokenDataAccess', this.tokenDataAccess);
            }
            this.loadFeatureFlags(this.token).subscribe();
            this.getSeenArticles().subscribe((articles) => {
              this.seenArticles = articles;
            });
            if (this.config.appConfig.setup.useStorage) {
              if (sessionStorage.getItem('track_login') !== user.email) {
                sessionStorage.setItem('track_login', user.email);
                if (localStorage.getItem('impersonate') === user.email) {
                  this.trackLoginSession(true).subscribe();
                } else {
                  this.trackLoginSession(false).subscribe();
                }
              }
            }
          }
        }),
        catchError((error) => this.handleError(error)),
      );
  }

  /**
   * autentification of the user login
   */
  authLogin2fa(email, code): Observable<any> {
    return this.http
      .post(
        this.authapi + '/v5/authenticate2f',
        {
          email: email,
          code: code,
        },
        {
          headers: new HttpHeaders().set('Content-Type', 'application/json'),
          responseType: 'json',
        },
      )
      .pipe(
        tap((result) => {
          const data: any = result;
          this.token = data.token;
          const user = jwt_decode(data.token);
          if (this.config.appConfig.setup.useStorage) {
            localStorage.setItem('token', this.token);
          }
          this.tokenDataAccess = data.data_access_token;
          if (this.config.appConfig.setup.useStorage) {
            localStorage.setItem('tokenDataAccess', this.tokenDataAccess);
          }
          this.loadFeatureFlags(this.token).subscribe();
          this.getSeenArticles().subscribe((articles) => {
            this.seenArticles = articles;
          });
          if (this.config.appConfig.setup.useStorage) {
            if (sessionStorage.getItem('track_login') !== user.email) {
              sessionStorage.setItem('track_login', user.email);
              if (localStorage.getItem('impersonate') === user.email) {
                this.trackLoginSession(true).subscribe();
              } else {
                this.trackLoginSession(false).subscribe();
              }
            }
          }
        }),
        catchError((error) => this.handleError(error)),
      );
  }

  /**
   * autentification of the token
   */
  authLoged(): Observable<any> {
    if (this.config.appConfig.setup.useStorage) {
      this.token = localStorage.getItem('token');
    }
    if (!this.token) {
      return of({});
    }
    return this.http
      .post(
        this.authapi + '/v5/validate/jwt',
        {
          token: this.token,
        },
        {
          headers: new HttpHeaders().set('Content-Type', 'application/json'),
          responseType: 'json',
        },
      )
      .pipe(
        tap((result) => {
          const data: any = result;
          this.token = data.token;
          const user = jwt_decode(data.token);
          this.tokenDataAccess = data.data_access_token;
          if (!this.routing.isReader() && !this.routing.isStoryReader()) {
            if (this.config.appConfig.setup.useStorage) {
              localStorage.setItem('token', this.token);
            }
            if (this.config.appConfig.setup.useStorage) {
              localStorage.setItem('tokenDataAccess', this.tokenDataAccess);
            }
            this.loadFeatureFlags(this.token).subscribe();
            this.getSeenArticles().subscribe((articles) => {
              this.seenArticles = articles;
            });
          }
          this.jwtAuthorized = true;
          if (this.config.appConfig.setup.useStorage) {
            if (sessionStorage.getItem('track_login') !== user.email) {
              sessionStorage.setItem('track_login', user.email);
              if (localStorage.getItem('impersonate') === user.email) {
                this.trackLoginSession(true).subscribe();
              } else {
                this.trackLoginSession(false).subscribe();
              }
            }
          }
        }),
        catchError((error) => this.handleError(error)),
      );
  }

  /**
   * autentification of the token
   */
  jwtAuth(token): Observable<any> {
    return this.http
      .post(
        this.authapi + '/v5/validate/jwt',
        {
          token: token,
        },
        {
          headers: new HttpHeaders().set('Content-Type', 'application/json'),
          responseType: 'json',
        },
      )
      .pipe(
        tap((result) => {
          const data: any = result;
          this.token = data.token;
          const user = jwt_decode(data.token);
          this.userdata = user;
          this.tokenDataAccess = data.data_access_token;
          let readonlyToken = false;
          if (
            user &&
            ((user.roles && user.roles.includes('WIDGET_READONLY')) ||
              (user.roles && user.roles.includes('WIDGET_READONLY')))
          ) {
            readonlyToken = true;
          }
          if (
            !this.routing.isReader() &&
            !this.routing.isStoryReader() &&
            !readonlyToken
          ) {
            if (!this.routing.isWidget()) {
              localStorage.setItem('token', this.token);
              localStorage.setItem('tokenDataAccess', this.tokenDataAccess);
            }
            this.loadFeatureFlags(this.token).subscribe();
            this.getSeenArticles().subscribe((articles) => {
              this.seenArticles = articles;
            });
          }
          if (
            !this.routing.isReader() &&
            !this.routing.isStoryReader() &&
            readonlyToken
          ) {
            this.router.navigate(['/message/autherror']);
          } else {
            this.jwtAuthorized = true;
          }
          if (this.config.appConfig.setup.useStorage) {
            if (sessionStorage.getItem('track_login') !== user.email) {
              sessionStorage.setItem('track_login', user.email);
              if (localStorage.getItem('impersonate') === user.email) {
                this.trackLoginSession(true).subscribe();
              } else {
                this.trackLoginSession(false).subscribe();
              }
            }
          }
        }),
        catchError((error) => this.handleError(error)),
      );
  }

  /**
   * getting token data
   */
  authToken() {
    let token;
    if (this.config.appConfig.setup.useStorage) {
      token = localStorage.getItem('token');
    }
    if (token) {
      this.userdata = jwt_decode(token);
      return this.userdata;
    } else if (!token && this.token) {
      this.userdata = jwt_decode(this.token);
      return this.userdata;
    } else {
      return null;
    }
  }

  /**
   * logout
   */
  authLogout() {
    this.portfoliosChange = true;
    this.queriesChange = true;
    this.collectionsChange = true;

    if (this.config.appConfig.setup.useStorage) {
      localStorage.removeItem('token');
      localStorage.removeItem('impersonate');
      sessionStorage.removeItem('track_login');
    }
    this.ngHttpCachingService.clearCache();
    this.featureFlags = Object.assign({}, this.defaultFlags);
    this.defaultFilter = undefined;
    this.filter = undefined;
    this.router.navigate(['/login']);
  }

  /**
   * change password knowing the old password
   */
  setPassword(password1, password2): Observable<any> {
    if (this.config.appConfig.setup.useStorage) {
      this.token = localStorage.getItem('token');
    }
    return this.http
      .post(
        this.authapi + '/v5/change_password',
        {
          old_password: password1,
          new_password: password2,
        },
        {
          headers: this.getHeader(),
          responseType: 'text',
        },
      )
      .pipe(catchError((error) => this.handleError(error)));
  }

  /**
   * request password reset for a specific email
   */
  requestPasswordReset(email, url): Observable<any> {
    return this.http
      .post(
        this.authapi + '/v5/request_password_reset',
        {
          email: email,
          password_reset_url: url,
        },
        {
          headers: this.getHeader(),
          responseType: 'text',
        },
      )
      .pipe(catchError((error) => this.handleError(error)));
  }

  /**
   * reset password based on the received token
   */
  resetPassword(email, token, password): Observable<any> {
    return this.http
      .post(
        this.authapi + '/v5/reset_password',
        {
          email: email,
          token: token,
          new_password: password,
        },
        {
          headers: this.getHeader(),
          responseType: 'text',
        },
      )
      .pipe(catchError((error) => this.handleError(error)));
  }

  // STATUS ---------------------------------------------------------------

  /**
   * check backend's maintenance status and get the status message/background, etc.
   */

  getBackendStatus(): Observable<any> {
    return this.http
      .get(this.authapi + '/v1/maintenance/status', {
        headers: this.getHeader(),
      })
      .pipe(catchError((error) => this.handleError(error)));
  }

  // PORTFOLIO ----------------------------------------------------------------

  /**
   * get specific portfolio by id
   */
  portFolio(uid): Observable<any> {
    return this.http
      .get(this.authapi + '/v4/portfolio', {
        params: { uid: uid },
        headers: this.getHeader(),
      })
      .pipe(catchError((error) => this.handleError(error)));
  }

  /**
   * get all the portfolios
   */
  portFolios(): Observable<any> {
    if (!this.portfoliosChange && this.cachedPortfolios) {
      return of(this.cachedPortfolios);
    } else {
      return this.http
        .get(this.authapi + '/v4/portfolio/all', {
          headers: this.getHeader(),
        })
        .pipe(
          map((f) => {
            const portfolios = f['hits'];
            this.portfoliosChange = false;
            this.cachedPortfolios = JSON.parse(JSON.stringify(portfolios));
            return portfolios;
          }),
          shareReplay(),
          catchError((error) => this.handleError(error)),
        );
    }
  }

  /**
   * get aggregated portfolios
   */
  getAggregatedPortfolios(): Observable<any> {
    return this.http
      .get(this.authapi + '/v1/aggregated_portfolios', {
        headers: this.getHeader(),
      })
      .pipe(catchError((error) => this.handleError(error)));
  }

  /**
   * create a new portfolio with name
   */
  newFolio(name, type = 'regular'): Observable<any> {
    this.portfoliosChange = true;

    const path =
      type === 'aggregated'
        ? '/v1/aggregated_portfolios/create'
        : '/v3/portfolio/new';
    return this.http
      .get(this.authapi + '' + path, {
        params: { name: name },
        headers: this.getHeader(),
      })
      .pipe(catchError((error) => this.handleError(error)));
  }

  /**
   * rename specific portfolio
   */
  renamePortfolio(name): Observable<any> {
    this.portfoliosChange = true;

    let request;
    if (this.folio && this.folio.is_aggregated) {
      request = this.http.get(
        this.authapi +
          '/v1/aggregated_portfolios/' +
          this.folio.uid +
          '/rename',
        {
          params: { new_name: name },
          headers: this.getHeader(),
        },
      );
    } else {
      request = this.http.get(this.authapi + '/v3/portfolio/rename', {
        params: { uid: this.folio.uid, new_name: name },
        headers: this.getHeader(),
      });
    }
    return request.pipe(catchError((error) => this.handleError(error)));
  }

  /**
   * delete specific portfolio
   */
  deletePortfolio(id = null): Observable<any> {
    this.portfoliosChange = true;

    let request;
    if (this.folio && this.folio.is_aggregated) {
      request = this.http.delete(
        this.authapi +
          '/v1/aggregated_portfolios/' +
          (id ? id : this.folio.uid),
        {
          headers: this.getHeader(),
        },
      );
    } else {
      request = this.http.get(this.authapi + '/v3/portfolio/delete', {
        params: { uid: id ? id : this.folio.uid },
        headers: this.getHeader(),
      });
    }
    return request.pipe(
      tap(() => {
        if (
          this.folio.uid === this.customDefaultPortfolio ||
          (id && id === this.customDefaultPortfolio)
        ) {
          this.customDefaultPortfolio = '';
          delete this.cockpitSettings['customDefaultPortfolio'];
          const newCustomSettings = Object.assign({}, this.cockpitSettings);
          this.setCustomSettings(newCustomSettings).subscribe();
        }
      }),
      catchError((error) => this.handleError(error)),
    );
  }

  /**
   * change the content of specific portfolio
   */
  // addEntity(content): Observable<any> {
  //   return this.http.post(
  //     this.authapi + '/v3/portfolio/add',
  //     {
  //       uid: this.folio.uid,
  //       content: content
  //     },
  //     {
  //       headers: this.getHeader(),
  //     }
  //   ).pipe(
  //     catchError(error => this.handleError(error))
  //   );
  // }

  /**
   * add entities array to the portfolio of specific id
   */
  // addEntitiesToPortfolio(content, id): Observable<any> {
  //   return this.http.post(
  //     this.authapi + '/v3/portfolio/add',
  //     {
  //       uid: id,
  //       content: content
  //     },
  //     {
  //       headers: this.getHeader(),
  //     }
  //   ).pipe(
  //     catchError(error => this.handleError(error))
  //   );
  // }

  /**
   * change the content of specific portfolio
   */
  addEntity(content): Observable<any> {
    this.portfoliosChange = true;

    return this.http
      .post(
        this.authapi + '/v4/portfolio/add',
        {
          portfolio_ids: [this.folio.uid],
          content: content,
        },
        {
          headers: this.getHeader(),
        },
      )
      .pipe(catchError((error) => this.handleError(error)));
  }

  /**
   * add entities array to an array of portfolios of specific ids
   */
  addEntitiesToPortfolios(content, portfoliosIds): Observable<any> {
    this.portfoliosChange = true;

    return this.http
      .post(
        this.authapi + '/v4/portfolio/add',
        {
          portfolio_ids: portfoliosIds,
          content: content,
        },
        {
          headers: this.getHeader(),
        },
      )
      .pipe(catchError((error) => this.handleError(error)));
  }

  /**
   * aggregate portfolios
   */
  aggregatePortfolios(content, id): Observable<any> {
    this.portfoliosChange = true;

    return this.http
      .post(
        this.authapi + '/v1/aggregated_portfolios/update',
        {
          uid: id,
          content: content,
        },
        {
          headers: this.getHeader(),
        },
      )
      .pipe(catchError((error) => this.handleError(error)));
  }

  /**
   * delete specific portfolio
   */
  deleteEntity(entity): Observable<any> {
    this.portfoliosChange = true;

    return this.http
      .get(this.authapi + '/v3/portfolio/delete', {
        params: { uid: this.folio.uid, entry: entity },
        headers: this.getHeader(),
      })
      .pipe(catchError((error) => this.handleError(error)));
  }

  /**
   * create multiple portfolio from a list
   */
  setFolios(data): Observable<any> {
    this.portfoliosChange = true;

    return this.http
      .post(this.authapi + '/v3/portfolio/add_bulk', data, {
        headers: this.getHeader(),
        responseType: 'text',
      })
      .pipe(catchError((error) => this.handleError(error)));
  }

  // SHARING PORTFOLIOS WITH OTHER USERS

  /**
   * list of all portfolios shared by a particular user with others
   */
  getSharedPortfolios(): Observable<any> {
    return this.http
      .get(this.authapi + '/v1/shared_portfolios/mine', {
        headers: this.getHeader(),
      })
      .pipe(catchError((error) => this.handleError(error)));
  }

  /**
   * mark a portfolio as shared
   */
  createSharedPortfolio(id, mode): Observable<any> {
    return this.http
      .get(this.authapi + '/v1/shared_portfolios/create', {
        params: {
          portfolio_id: id,
          mode: mode,
        },
        headers: this.getHeader(),
      })
      .pipe(catchError((error) => this.handleError(error)));
  }

  /**
   * share a portfolio with given users
   */
  sharePortfolio(id, users): Observable<any> {
    return this.http
      .post(
        this.authapi + '/v1/shared_portfolios/' + id + '/share',
        {
          users: users,
        },
        {
          headers: this.getHeader(),
        },
      )
      .pipe(catchError((error) => this.handleError(error)));
  }

  /**
   * unshare from a given user
   */
  unsharePortfolio(id, email): Observable<any> {
    return this.http
      .get(this.authapi + '/v1/shared_portfolios/' + id + '/unshare', {
        params: { user: email },
        headers: this.getHeader(),
      })
      .pipe(catchError((error) => this.handleError(error)));
  }

  /**
   * deactivate given shared portfolio
   */
  deactivateSharedPortfolio(id): Observable<any> {
    return this.http
      .get(this.authapi + '/v1/shared_portfolios/' + id + '/deactivate', {
        headers: this.getHeader(),
      })
      .pipe(catchError((error) => this.handleError(error)));
  }

  /**
   * deactivate given shared portfolio
   */
  activateSharedPortfolio(id): Observable<any> {
    return this.http
      .get(this.authapi + '/v1/shared_portfolios/' + id + '/activate', {
        headers: this.getHeader(),
      })
      .pipe(catchError((error) => this.handleError(error)));
  }

  /**
   * list all users given portfolio is shared with
   */
  getSharedPortfolioUsers(id): Observable<any> {
    return this.http
      .get(this.authapi + '/v1/shared_portfolios/' + id, {
        headers: this.getHeader(),
      })
      .pipe(
        map((resp) => {
          const respCopy = JSON.parse(JSON.stringify(resp));
          return { users: respCopy['shared_with'] };
        }),
        catchError((error) => this.handleError(error)),
      );
  }

  /**
   * remove from database everything related to given shared portfolio
   */
  removeSharedPortfolio(id): Observable<any> {
    return this.http
      .delete(this.authapi + '/v1/shared_portfolios/' + id, {
        headers: this.getHeader(),
      })
      .pipe(catchError((error) => this.handleError(error)));
  }

  // NOTE -------------------------------------------------------------------

  /**
   * get all the annotations of specific project
   */
  getNoteAll(): Observable<any> {
    return this.http.get(this.authapi + '/v3/chartanno/view', {
      params: { project: this.config.appConfig.routing.authpro },
      headers: this.getHeader(),
    });
  }

  /**
   * get all annotation from an entity
   */
  getNote(entity): Observable<any> {
    return this.http.get(this.authapi + '/v3/chartanno/viewByEntity', {
      params: {
        project: this.config.appConfig.routing.authpro,
        entity: entity,
      },
      headers: this.getHeader(),
    });
  }

  /**
   * bet annotation from id
   */
  getNoteByid(id): Observable<any> {
    return this.http.get(this.authapi + '/v3/chartanno/viewById', {
      params: {
        project: this.config.appConfig.routing.authpro,
        id: id,
      },
      headers: this.getHeader(),
    });
  }

  /**
   * create an annotation
   */
  addNote(data): Observable<any> {
    return this.http.post(this.authapi + '/v3/chartanno/add', data, {
      headers: this.getHeader(),
      responseType: 'text',
    });
  }

  /**
   * delete an annotation
   */
  delNote(item): Observable<any> {
    return this.http.get(this.authapi + '/v3/chartanno/delete', {
      params: {
        project: this.config.appConfig.routing.authpro,
        uid: item.uid,
        entity: item.entity,
      },
      headers: this.getHeader(),
      responseType: 'text',
    });
  }

  // QUERY -------------------------------------------------------------------

  /**
   * delete a query group
   */
  deleteGroupQuery(): Observable<any> {
    this.queriesChange = true;
    this.collectionsChange = true;

    return this.http
      .get(this.authapi + '/v1/search_query/group/delete', {
        params: { uid: this.query.uid },
        headers: this.getHeader(),
      })
      .pipe(catchError((error) => this.handleError(error)));
  }

  /**
   * edit an query group
   */
  editGroupQuery(name): Observable<any> {
    this.queriesChange = true;
    this.collectionsChange = true;

    return this.http
      .get(this.authapi + '/v1/search_query/group/rename', {
        params: { uid: this.query.uid, new_name: name },
        headers: this.getHeader(),
      })
      .pipe(catchError((error) => this.handleError(error)));
  }

  /**
   * add query group
   */
  addGroupQuery(name): Observable<any> {
    this.queriesChange = true;
    this.collectionsChange = true;

    return this.http
      .get(this.authapi + '/v1/search_query/group/new', {
        params: { name: name },
        headers: this.getHeader(),
      })
      .pipe(catchError((error) => this.handleError(error)));
  }

  /**
   * get a group query
   */
  getGroupsQuery(): Observable<any> {
    if (!this.collectionsChange && this.cachedCollections) {
      return of(this.cachedCollections);
    } else {
      return this.http
        .get(this.authapi + '/v1/search_query/group/view', {
          headers: this.getHeader(),
        })
        .pipe(
          map((collections) => {
            this.collectionsChange = false;
            this.cachedCollections = JSON.parse(JSON.stringify(collections));
            return collections;
          }),
          shareReplay(),
          catchError((error) => this.handleError(error)),
        );
    }
  }

  /**
   * get all the querys
   */
  getQuery(): Observable<any> {
    if (!this.queriesChange && this.cachedQueries) {
      return of(this.cachedQueries);
    } else {
      this.queriesChange = false;
      return this.http
        .get(this.authapi + '/v1/search_query/get', {
          headers: this.getHeader(),
        })
        .pipe(
          map((queries) => {
            this.cachedQueries = JSON.parse(JSON.stringify(queries));
            return queries;
          }),
          shareReplay(),
          catchError((error) => this.handleError(error)),
        );
    }
  }

  /**
   * get specific group query
   */
  getQueryGroup(id): Observable<any> {
    return this.http
      .get(this.authapi + '/v1/search_query/get', {
        params: { group: id },
        headers: this.getHeader(),
      })
      .pipe(catchError((error) => this.handleError(error)));
  }

  /**
   * save specific query
   */
  addQuery(data): Observable<any> {
    this.queriesChange = true;
    this.collectionsChange = true;

    return this.http
      .post(this.authapi + '/v1/search_query/save', data, {
        headers: this.getHeader(),
      })
      .pipe(catchError((error) => this.handleError(error)));
  }

  /**
   * save multiple queries
   */
  addQueries(data): Observable<any> {
    this.queriesChange = true;
    this.collectionsChange = true;

    return this.http
      .post(this.authapi + '/v1/search_query/save_array', data, {
        headers: this.getHeader(),
      })
      .pipe(catchError((error) => this.handleError(error)));
  }

  /**
   * delete specific query by id (DEPRECATED)
   */
  delQuery(uid): Observable<any> {
    this.queriesChange = true;
    this.collectionsChange = true;

    return this.http
      .get(this.authapi + '/v1/search_query/delete?uid=' + uid, {
        headers: this.getHeader(),
      })
      .pipe(catchError((error) => this.handleError(error)));
  }

  /**
   * delete array of queries by ids
   */
  delQueries(idsArray): Observable<any> {
    this.queriesChange = true;
    this.collectionsChange = true;

    return this.http
      .delete(this.authapi + '/v1/search_query', {
        body: {
          uids: idsArray,
        },
        headers: this.getHeader(),
      })
      .pipe(catchError((error) => this.handleError(error)));
  }

  /**
   * add single query to a particular group (DEPRECATED)
   */
  addQueryToGroup(queryId, groupId): Observable<any> {
    return this.http
      .get(
        this.authapi +
          '/v1/search_query/' +
          queryId +
          '/add_group?group=' +
          groupId,
        {
          headers: this.getHeader(),
        },
      )
      .pipe(catchError((error) => this.handleError(error)));
  }

  /**
   * add multiple queries to a particular group
   */
  addQueriesToGroup(queriesIds, groupId): Observable<any> {
    this.queriesChange = true;
    this.collectionsChange = true;

    return this.http
      .post(
        this.authapi + '/v1/search_query/assign_to_group',
        {
          group: groupId,
          query_ids: queriesIds,
        },
        {
          headers: this.getHeader(),
        },
      )
      .pipe(catchError((error) => this.handleError(error)));
  }

  /**
   * remove single query from a particular group (DEPRECATED)
   */
  removeQueryFromGroup(queryId, groupId): Observable<any> {
    return this.http
      .get(
        this.authapi +
          '/v1/search_query/' +
          queryId +
          '/remove_group?group=' +
          groupId,
        {
          headers: this.getHeader(),
        },
      )
      .pipe(catchError((error) => this.handleError(error)));
  }

  /**
   * remove multiple queries from a particular group
   */
  removeQueriesFromGroup(queriesIds, groupId): Observable<any> {
    this.queriesChange = true;
    this.collectionsChange = true;

    return this.http
      .post(
        this.authapi + '/v1/search_query/remove_from_group',
        {
          group: groupId,
          query_ids: queriesIds,
        },
        {
          headers: this.getHeader(),
        },
      )
      .pipe(catchError((error) => this.handleError(error)));
  }

  /**
   * resolve query for linked artifacts
   */
  resolveQuery(uid): Observable<any> {
    return this.http
      .get(this.authapi + '/v1/search_query/resolve?uid=' + uid, {
        headers: this.getHeader(),
      })
      .pipe(catchError((error) => this.handleError(error)));
  }

  /**
   * resolve query group for linked artifacts
   */
  resolveQueryGroup(uid): Observable<any> {
    return this.http
      .get(this.authapi + '/v1/search_query/group/resolve?uid=' + uid, {
        headers: this.getHeader(),
      })
      .pipe(catchError((error) => this.handleError(error)));
  }

  // QUERY ALERTS & NOTIFICATIONS -------------------------------------------------

  /**
   * get notifications
   */
  getNotifications(howMany): Observable<any> {
    const path = howMany ? '?n=' + howMany : '';
    return this.http
      .get(this.authapi + '/v1/notifications' + path, {
        headers: this.getHeader(),
      })
      .pipe(catchError((error) => this.handleError(error)));
  }

  /**
   * mark notifications as seen
   */
  markNotifications(): Observable<any> {
    return this.http
      .get(this.authapi + '/v1/notifications/mark_as_seen', {
        headers: this.getHeader(),
      })
      .pipe(catchError((error) => this.handleError(error)));
  }

  /**
   * get list of all of the query alerts
   */
  getQueryAlerts(): Observable<any> {
    return this.http
      .get(this.authapi + '/v1/query_alerts', {
        headers: this.getHeader(),
      })
      .pipe(catchError((error) => this.handleError(error)));
  }

  /**
   * create alert for the query and activate it
   */
  createQueryAlert(queryId): Observable<any> {
    return this.http
      .get(this.authapi + '/v1/query_alerts/create?query_id=' + queryId, {
        headers: this.getHeader(),
      })
      .pipe(catchError((error) => this.handleError(error)));
  }

  /**
   * deactivate/activate query alert
   */
  setQueryAlert(alertId, activate): Observable<any> {
    const path = activate ? 'activate' : 'deactivate';
    return this.http
      .get(this.authapi + '/v1/query_alerts/' + alertId + '/' + path, {
        headers: this.getHeader(),
      })
      .pipe(catchError((error) => this.handleError(error)));
  }

  /**
   * delete query alert
   */
  deleteQueryAlert(alertId): Observable<any> {
    return this.http
      .delete(this.authapi + '/v1/query_alerts/' + alertId, {
        headers: this.getHeader(),
      })
      .pipe(catchError((error) => this.handleError(error)));
  }

  // SCORE BASED ALERTS -----------------------------------------------------------

  // /**
  //  * get all alerts
  //  */
  // getAlerts(): Observable<any> {
  //   return this.http.get(
  //     this.authapi + '/v1/api_alerts/get',
  //     {
  //       headers: this.getHeader()
  //     }
  //   ).pipe(
  //     catchError(error => this.handleError(error))
  //   );
  // }
  //
  // /**
  //  * create or update specific alert
  //  */
  // addAlert(data): Observable<any> {
  //   return this.http.post(
  //     this.authapi + '/v1/api_alerts/add',
  //     data,
  //     {
  //       headers: this.getHeader()
  //     }
  //   ).pipe(
  //     catchError(error => this.handleError(error))
  //   );
  // }
  //
  // /**
  //  * remove specific alert
  //  */
  // removeAlert(uid): Observable<any> {
  //   return this.http.get(
  //     this.authapi + '/v1/api_alerts/delete/' + uid,
  //     {
  //       headers: this.getHeader()
  //     }
  //   ).pipe(
  //     catchError(error => this.handleError(error))
  //   );
  // }
  //
  // /**
  //  * get all alerts
  //  */
  // getMultiAlerts(): Observable<any> {
  //   return this.http.get(
  //     this.authapi + '/v1/multi_api_alerts/get',
  //     {
  //       headers: this.getHeader()
  //     }
  //   ).pipe(
  //     catchError(error => this.handleError(error))
  //   );
  // }
  //
  // /**
  //  * create or update specific alert
  //  */
  // addMultiAlert(data): Observable<any> {
  //   return this.http.post(
  //     this.authapi + '/v1/multi_api_alerts/add',
  //     data,
  //     {
  //       headers: this.getHeader()
  //     }
  //   ).pipe(
  //     catchError(error => this.handleError(error))
  //   );
  // }
  //
  // /**
  //  * remove specific alert
  //  */
  // removeMultiAlert(uid): Observable<any> {
  //   return this.http.get(
  //     this.authapi + '/v1/multi_api_alerts/delete/' + uid,
  //     {
  //       headers: this.getHeader()
  //     }
  //   ).pipe(
  //     catchError(error => this.handleError(error))
  //   );
  // }

  // SCORE/EVENTS BASED ALERTS -----------------------------------------------------------

  /**
   * get score/event alerts
   */
  getAlerts(type): Observable<any> {
    return this.http
      .get(this.authapi + '/v1/alerts?alert_type=' + type, {
        headers: this.getHeader(),
      })
      .pipe(catchError((error) => this.handleError(error)));
  }

  /**
   * create or update specific alert
   */
  addAlert(data): Observable<any> {
    return this.http
      .post(this.authapi + '/v1/alerts', data, {
        headers: this.getHeader(),
      })
      .pipe(catchError((error) => this.handleError(error)));
  }

  /**
   * remove specific alert
   */
  removeAlert(uid): Observable<any> {
    return this.http
      .delete(this.authapi + '/v1/alerts/' + uid, {
        headers: this.getHeader(),
      })
      .pipe(catchError((error) => this.handleError(error)));
  }

  /**
   * activate specific alert
   */
  activateAlert(uid): Observable<any> {
    return this.http
      .get(this.authapi + '/v1/alerts/activate/' + uid, {
        headers: this.getHeader(),
      })
      .pipe(catchError((error) => this.handleError(error)));
  }

  /**
   * deactivate specific alert
   */
  deactivateAlert(uid): Observable<any> {
    return this.http
      .get(this.authapi + '/v1/alerts/deactivate/' + uid, {
        headers: this.getHeader(),
      })
      .pipe(catchError((error) => this.handleError(error)));
  }

  // NEWSLETTER -------------------------------------------------------------------

  /**
   * get all the newsletters
   */
  allNewsletter(): Observable<any> {
    return this.http
      .get(this.authapi + '/v3/newsletter/subscriptions', {
        headers: this.getHeader(),
      })
      .pipe(
        shareReplay(),
        map((res) => {
          const resCopy = JSON.parse(JSON.stringify(res));
          return resCopy['hits'];
        }),
        catchError((error) => this.handleError(error)),
      );
  }

  /**
   * update specific newsletter
   */
  editNewsletter(data): Observable<any> {
    return this.http
      .post(this.authapi + '/v2/newsletter/update', data, {
        headers: this.getHeader(),
      })
      .pipe(catchError((error) => this.handleError(error)));
  }

  /**
   * remove specific newsletter
   */
  removeNewsletter(uid): Observable<any> {
    return this.http.get(this.authapi + '/v2/newsletter/remove', {
      params: { uid: uid },
      headers: this.getHeader(),
    });
  }

  /**
   * generate and send newsletter immediately once
   */
  generateNewsletter(data): Observable<any> {
    return this.http
      .post(this.newsletterAPI + '/generate_one', data, {
        headers: this.getHeader(),
      })
      .pipe(catchError((error) => this.handleError(error)));
  }

  /**
   * generate newsletter's preview
   */
  previewNewsletter(id, from): Observable<any> {
    return this.http
      .post(
        this.newsletterAPI + '/api/create_layout',
        // {uid: id},
        { uid: id, from: from, to: from + 1 },
        {
          headers: this.getHeader(),
        },
      )
      .pipe(catchError((error) => this.handleError(error)));
  }

  /**
   * getting available scores for newsletter's charts
   */
  getNewsletterScores(): Observable<any> {
    return this.http.get(this.newsletterAPI + '/available_scores', {
      headers: this.getHeader(),
    });
  }

  /**
   * dismiss news for newsletter (DEPRECATED)
   */
  newsletterDismiss(id): Observable<any> {
    return this.http.get(this.authapi + '/v1/news_dismissal/dismiss', {
      params: { project: this.config.appConfig.routing.apiproj, news_id: id },
      headers: this.getHeader(),
      responseType: 'text',
    });
  }

  /**
   * dismiss news array for newsletter
   */
  newsletterDismissNews(idsArray, newsletterId): Observable<any> {
    const context = 'ylrn:artifact:newsletter:' + newsletterId;
    if (newsletterId) {
      return this.http.post(
        this.authapi + '/v2/news_dismissal/dismiss',
        {
          news_ids: idsArray,
          context: context,
        },
        {
          headers: this.getHeader(),
          responseType: 'text',
        },
      );
    } else {
      return this.http.post(
        this.authapi + '/v2/news_dismissal/dismiss',
        {
          news_ids: idsArray,
        },
        {
          headers: this.getHeader(),
          responseType: 'text',
        },
      );
    }
  }

  /**
   * undismiss news for newsletter
   */
  newsletterUndismiss(id): Observable<any> {
    return this.http.get(this.authapi + '/v1/news_dismissal/undismiss', {
      params: { news_id: id },
      headers: this.getHeader(),
      responseType: 'text',
    });
  }

  /**
   * get all news dismissed for newsletter
   */
  getNewsletterDismissed(newsletterId): Observable<any> {
    let url = '/v2/news_dismissal/mine?limit=300';
    if (newsletterId) {
      url =
        '/v2/news_dismissal/mine?limit=300&context=ylrn:artifact:newsletter:' +
        newsletterId;
    }
    return this.http.get(this.authapi + '' + url, {
      headers: this.getHeader(),
    });
  }

  // INVESTMENT -------------------------------------------------------------------

  /**
   *  get alert of all indicator
   */
  getIndicator(): Observable<any> {
    return this.http.get(this.authapi + '/v1/investment_ratio_alerts/get', {
      headers: this.getHeader(),
    });
  }

  /**
   * get email alert of specific indicator
   */
  setIndicator(alert, iparams): Observable<any> {
    let requestUrl = this.authapi + '/v1/investment_ratio_alerts/activate';
    if (!alert) {
      requestUrl = this.authapi + '/v1/investment_ratio_alerts/deactivate';
    }
    return this.http.get(requestUrl, {
      params: {
        sector: iparams.id,
        strategy: iparams.overlay,
      },
      headers: this.getHeader(),
    });
  }

  // REPORT -------------------------------------------------------------------

  /**
   * trigger slack message for each report
   */
  iSlack(data): Observable<any> {
    const domain = 'https://app.yukkalab.com';
    const hook =
      'https://hooks.slack.com/services/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';
    // if (this.config.appConfig.environment === 'dev') { hook = 'https://hooks.slack.com/services/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' }
    const message = {
      attachments: [
        {
          color: '#D00000',
          title:
            '<' +
            domain +
            '/admin/report?query=id:equal:' +
            data.id.id +
            '| Report - ' +
            data.report.report +
            '>',
          author_name:
            '<' +
            domain +
            '/admin/report?query=email:equal:' +
            data.report.email +
            '|' +
            data.report.email +
            '>',
          text: data.report.message,
        },
      ],
    };
    return this.http.post(hook, message, {
      headers: new HttpHeaders({
        'Content-Type': 'text/plain',
      }),
    });
  }

  /**
   * find specific reports
   */
  findReports(type, size): Observable<any> {
    let params = new HttpParams();
    if (type) {
      params = params.append('type', type);
    }
    if (size) {
      params = params.append('size', size);
    }
    return this.http.get(this.authapi + '/v2/cockpit_reports/find', {
      params: params,
      headers: this.getHeader(),
    });
  }

  /**
   * find reports by id
   */
  findReportById(id): Observable<any> {
    return this.http.get(this.authapi + '/v2/cockpit_reports/findId/' + id, {
      headers: this.getHeader(),
    });
  }

  /**
   * save a report
   */
  storeReport(data): Observable<any> {
    return this.http.post(this.authapi + '/v2/cockpit_reports/store', data, {
      headers: this.getHeader(),
    });
  }

  /**
   * change report's label
   */
  changeReportLabel(data): Observable<any> {
    return this.http.post(
      this.authapi + '/v1/cockpit_reports/set_label',
      data,
      {
        headers: this.getHeader(),
      },
    );
  }

  /**
   * remove a report
   */
  // removeReport(id): Observable<any> {
  //   return this.http.get(
  //     this.authapi + '/v2/cockpit_reports/remove',
  //     {
  //       params: { id: id, },
  //       headers: this.getHeader()
  //     }
  //   );
  // }

  /**
   * remove multiple reports
   */
  removeMultipleReports(idsArray): Observable<any> {
    return this.http.request('delete', this.authapi + '/v3/cockpit_reports', {
      headers: this.getHeader(),
      body: {
        ids: idsArray,
      },
    });
  }

  // REQUEST ENTITY

  /**
   * add requested entity
   */
  addRequestEntity(data): Observable<any> {
    return this.http.post(this.authapi + '/v1/entity_requests/add', data, {
      headers: this.getHeader(),
    });
  }

  // AUDIT -----------------------------------------------------------------------

  /**
   * start news audit
   */
  newsAudit(data): Observable<any> {
    return this.http.post(this.authapi + '/v1/news_audits/start', data, {
      headers: this.getHeader(),
    });
  }

  /**
   * dismiss news from audit
   */
  newsDismiss(data): Observable<any> {
    return this.http.post(this.authapi + '/v1/news_audits/dismiss', data, {
      headers: this.getHeader(),
    });
  }

  /**
   * resolve news audit
   */
  newsResolve(data): Observable<any> {
    return this.http.post(this.authapi + '/v1/news_audits/resolve', data, {
      headers: this.getHeader(),
    });
  }

  /**
   * get all audited cases
   */
  getAuditedCases(data): Observable<any> {
    return this.http.post(this.authapi + '/v1/news_audits', data, {
      headers: this.getHeader(),
    });
  }

  /**
   * get all dismissed cases
   */
  getDismissedCases(data): Observable<any> {
    return this.http.post(this.authapi + '/v1/news_audits/dismissed', data, {
      headers: this.getHeader(),
    });
  }

  /**
   * get opened cases articles ids
   */
  getOpenedIds(): Observable<any> {
    return this.http.get(this.authapi + '/v1/news_audits/open/articles', {
      headers: this.getHeader(),
    });
  }

  /**
   * get resolved cases articles ids
   */
  getResolvedIds(): Observable<any> {
    return this.http.get(this.authapi + '/v1/news_audits/resolved/articles', {
      headers: this.getHeader(),
    });
  }

  /**
   * get audited articles ids
   */
  getAuditedIds(): Observable<any> {
    return this.http.get(this.authapi + '/v1/news_audits/articles', {
      headers: this.getHeader(),
    });
  }

  /**
   * get dismissed articles ids
   */
  getDismissedIds(): Observable<any> {
    return this.http.get(this.authapi + '/v1/news_audits/dismissed/articles', {
      headers: this.getHeader(),
    });
  }

  /**
   * get audited articles subjects
   */
  getAuditedSubjects(): Observable<any> {
    return this.http.get(this.authapi + '/v1/news_audits/subjects', {
      headers: this.getHeader(),
    });
  }

  /**
   * get dismissed articles subjects
   */
  getDismissedSubjects(): Observable<any> {
    return this.http.get(this.authapi + '/v1/news_audits/dismissed/subjects', {
      headers: this.getHeader(),
    });
  }

  /**
   * get xls file for export
   */
  getAuditsXLSX(): Observable<any> {
    return this.http.get(this.authapi + '/v1/news_audits/export', {
      headers: this.getHeader(),
      responseType: 'blob',
    });
  }

  // PREFERRED -------------------------------------------------------------------

  /**
   * get all the preferred news
   */
  getStars(): Observable<any> {
    return this.http.get(this.authapi + '/v1/news_curation/mine?limit=300', {
      headers: this.getHeader(),
    });
  }

  /**
   * save a news preferred
   */
  addStars(id): Observable<any> {
    return this.http.get(this.authapi + '/v1/news_curation/star', {
      params: { project: this.config.appConfig.routing.apiproj, news_id: id },
      headers: this.getHeader(),
      responseType: 'text',
    });
  }

  /**
   * import multiple bookmarks
   */
  importStars(data): Observable<any> {
    return this.http.post(this.authapi + '/v1/news_curation/import', data, {
      headers: this.getHeader(),
    });
  }

  /**
   * delete a news preferred
   */
  delStars(id): Observable<any> {
    return this.http.get(this.authapi + '/v1/news_curation/unstar', {
      params: { news_id: id },
      headers: this.getHeader(),
      responseType: 'text',
    });
  }

  // FILTER -------------------------------------------------------------------

  /**
   * get all the filter collection
   */
  getFilter(): Observable<any> {
    return this.http
      .get(this.authapi + '/v1/cockpit_filters/get', {
        headers: this.getHeader(),
      })
      .pipe(
        shareReplay(),
        catchError((error) => this.handleError(error)),
      );
  }

  /**
   * delete a filter collection
   */
  delFilter(id): Observable<any> {
    return this.http.get(this.authapi + '/v1/cockpit_filters/remove', {
      params: { id: id },
      headers: this.getHeader(),
    });
  }

  /**
   * add a filter collection
   */
  setFilter(data): Observable<any> {
    return this.http.post(this.authapi + '/v1/cockpit_filters/store', data, {
      headers: this.getHeader(),
    });
  }

  // USER ACTIVITY -------------------------------------------------------------------

  /**
   * record a user activity
   */
  userActivity(data) {
    if (data.activity_type === 'article_open') {
      if (this.seenArticles && !this.seenArticles.includes(data.article_id)) {
        this.seenArticles.unshift(data.article_id);
      }
    }
    return this.http.post(this.authapi + '/v1/user_activity/event', data, {
      headers: this.getHeader(),
    });
  }

  /**
   * get n most recent clicks on the search bar
   */
  getSearchActivity(n) {
    return this.http.get(this.authapi + '/v1/user_activity/recent', {
      params: {
        size: n,
      },
      headers: this.getHeader(),
    });
  }

  /**
   * get previously seen articles from user_activity endpoint
   */
  getSeenArticles() {
    return this.http.get(this.authapi + '/v1/user_activity/seen_articles', {
      headers: this.getHeader(),
    });
  }

  /**
   * post referrer info from the newsletter's link to the Customer API
   */
  postReferrer(source, url, payload) {
    return this.http.post(
      this.authapi + '/v1/referrer_tracking',
      {
        source: source,
        url: url,
        payload: payload,
      },
      {
        headers: this.getHeader(),
      },
    );
  }

  /**
   * show warning flag
   */
  showWarningFlagLoader() {
    this.showWarningFlag$.next(true);
  }

  /**
   * hide warning flag
   */
  hideWarningFlagLoader() {
    this.showWarningFlag$.next(false);
  }

  /**
   * show caution flag
   */
  showCautionFlagLoader() {
    this.showCautionFlag$.next(true);
  }

  /**
   * hide caution flag
   */
  hideCautionFlagLoader() {
    this.showCautionFlag$.next(false);
  }

  /**
   * sending feedback message to HubSpot
   */
  sendUserFeedback(title: string, message: string) {
    return this.http.post(
      this.authapi + '/v1/support_requests/open',
      {
        subject: title,
        content: message,
      },
      {
        headers: this.getHeader(),
      },
    );
  }

  // GETTING REPORTS STATUS

  /**
   * get report's status
   */
  // getReportStatus(company, type, date) {
  //   return this.http.get(
  //     this.authapi + '/v1/reporting/report_status',
  //     {
  //       params: {
  //         company: company,
  //         report_type: type,
  //         date: date
  //       },
  //       headers: this.getHeader()
  //     }
  //   );
  // }

  // REPORTING SERVICE

  /**
   * requesting entity reports to be sent by email
   */
  sendReport(company, type, date) {
    return this.http.post(
      this.reportingServiceAPI + '/api/company_report',
      {
        date: date + '',
        company_id: company,
        report_type: type,
      },
      {
        headers: this.getHeader(),
      },
    );
  }

  /**
   * requesting portfolio reports to be sent by email
   */
  sendReportPortfolio(portfolio, companies, type, date) {
    return this.http.post(
      this.reportingServiceAPI + '/api/portfolio_report',
      {
        date: date + '',
        portfolio_name: portfolio,
        company_ids: companies,
        report_type: type,
      },
      {
        headers: this.getHeader(),
      },
    );
  }

  /**
   * get report's status for company
   */
  getReportStatus(company, type, date) {
    return this.http.get(this.reportingServiceAPI + '/api/report_status', {
      params: {
        company_id: company,
        report_type: type,
        date: date,
      },
      headers: this.getHeader(),
    });
  }

  /**
   * get report's status for portfolio
   */
  getReportStatusPortfolio(portfolio, companies, type, date) {
    return this.http.post(
      this.reportingServiceAPI + '/api/portfolio_report_status',
      {
        portfolio_name: portfolio,
        company_ids: companies,
        report_type: type,
        date: date,
      },
      {
        headers: this.getHeader(),
      },
    );
  }

  // ALERTING SERVICE
  /**
   * trigger score/event alerts immediately
   */
  triggerAlert(uid) {
    return this.http.get(this.alertingServiceAPI + '/v2/alert/trigger', {
      params: {
        uid: uid,
        created_by: this.userdata?.email,
      },
      headers: this.getHeader(),
    });
  }

  /**
   * get alerts history
   */
  getAlertHistory(uid) {
    return this.http.get(this.alertingServiceAPI + '/channel/history', {
      params: {
        uid: uid,
      },
      headers: this.getHeader(),
    });
  }

  // CHAT LLM DATA

  getChats() {
    const getChatsUrl = `${this.authapi}/v1/chats`;
    return this.http.get(getChatsUrl, {
      headers: this.getHeader(),
    });
  }

  getChatDetails(chatId: string) {
    const getChatDetailsUrl = `${this.authapi}/v1/chats/${chatId}`;
    return this.http.get(getChatDetailsUrl, {
      headers: this.getHeader(),
    });
  }

  startNewChat(title: string) {
    const startNewChatUrl = `${this.authapi}/v1/chats/start?title=${title}`;

    return this.http.get(startNewChatUrl, {
      headers: this.getHeader(),
    });
  }

  postChatConversation(chatId: string, actor, message) {
    const postChatConversationUrl = `${this.authapi}/v1/chats/${chatId}/message`;

    return this.http.post(
      postChatConversationUrl,
      {
        actor: actor,
        message: message,
      },
      {
        headers: this.getHeader(),
      },
    );
  }

  deleteChat(chatId: string) {
    const deleteChatUrl = `${this.authapi}/v1/chats/${chatId}`;

    return this.http.delete(deleteChatUrl, {
      headers: this.getHeader(),
    });
  }

  editChatTitle(chatTitle: string, chatId) {
    const editChatTitleUrl = `${this.authapi}/v1/chats/${chatId}/title?title=${chatTitle}`;

    return this.http.post(
      editChatTitleUrl,
      {
        title: chatTitle,
      },
      {
        headers: this.getHeader(),
      },
    );
  }

  // POST request : give positive or negative feedback on a chat_id's message_id
  postChatFeedback(chatId: string, messageId: string, feedback: string) {
    const postChatFeedbackUrl = `${this.authapi}/v1/chats/feedback?chat_id=${chatId}&message_id=${messageId}&feedback_type=${feedback}`;
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.token}`,
    });
    return this.http.post(postChatFeedbackUrl, {}, { headers: headers });
  }

  // DELETE request: remove feedback on a chat_id's message_id
  deleteChatFeedback(chatId: string, messageId: string) {
    const deleteChatFeedbackUrl = `${this.authapi}/v1/chats/feedback?chat_id=${chatId}&message_id=${messageId}`;
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.token}`,
    });
    return this.http.delete(deleteChatFeedbackUrl, { headers: headers });
  }

  /**
   * Sends a chat payload to the LLM chat API and returns an observable of the response.
   *
   * @param chatPayload - The payload to be sent to the chat API.
   * @returns An Observable of the API response.
   *
   */
  chatLLM(chatPayload: ChatPayload): Observable<any> {
    const requestUrl = `${this.apiurlChatLLM}/v2/chat`;
    return this.http
      .post(
        requestUrl,
        {
          ...chatPayload,
        },
        { headers: this.getHeader() },
      )
      .pipe(
        catchError((error) => {
          console.error('API error caught:', error); // Log any errors
          return throwError(() => error);
        }),
      );
  }
}
