import { Component } from '@angular/core';
import { ChatNavigationService } from '../../services/chat-navigation-service/chat-navigation-service';
import {
  DrawerType,
  MobileDrawerService,
} from 'src/app/project/mobile/sevices/mobile-drawer-service';

@Component({
  selector: 'app-chat-mobile-header',
  templateUrl: './chat-mobile-header.component.html',
  styleUrls: ['./chat-mobile-header.component.scss'],
})
export class ChatMobileHeaderComponent {
  constructor(
    private mobileDrawerService: MobileDrawerService,
    private chatNavigationService: ChatNavigationService,
  ) {}

  chatNavigationMenuOpen() {
    this.mobileDrawerService.openDrawer(DrawerType.CHAT);
  }

  startNewChat() {
    this.chatNavigationService.startNewChat();
  }
}
