/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Component,
  ViewEncapsulation,
  ViewChild,
  OnDestroy,
  AfterViewInit,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatDrawer } from '@angular/material/sidenav';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/service/auth.service';
import { RoutingService } from 'src/app/service/routing.service';
import { YukkApi } from 'src/app/service/yukkapi.service';
import {
  DrawerType,
  MobileDrawerService,
} from '../sevices/mobile-drawer-service';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-mobile-layout',
  templateUrl: './mobile-layout.component.html',
  styleUrls: ['./mobile-layout.component.scss'],
})
export class MobileLayoutComponent implements AfterViewInit, OnDestroy {
  @ViewChild('chatNavDrawer') chatNavDrawer: MatDrawer;
  @ViewChild('userNavDrawer') userNavDrawer: MatDrawer;
  isDrawerOpen = false;
  private destroy$ = new Subject<void>();
  hierarchy: boolean;
  entity: boolean;
  folios: any;
  params: any;
  user: any;
  listsignal: any;
  sentiment: any;
  promptEvent: any;

  constructor(
    private route: ActivatedRoute,
    public routing: RoutingService,
    private yukkApi: YukkApi,
    private auth: AuthService,
    public dialog: MatDialog,

    private mobileDrawerService: MobileDrawerService,
  ) {
    this.route.queryParams.subscribe((params) => {
      this.hierarchy = params.type === 'hierarchy';
      this.entity = params.type && params.type !== 'hierarchy';
      this.params = params;
    });

    this.folios = this.auth.portFolios();

    this.yukkApi.getSignalsList().subscribe((result) => {
      this.listsignal = result.entities;
    });
  }

  logOut() {
    this.auth.authLogout();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.mobileDrawerService.drawerState$
        .pipe(takeUntil(this.destroy$))
        .subscribe((state) => {
          if (this.chatNavDrawer) {
            if (state[DrawerType.CHAT]) {
              this.chatNavDrawer.open();
            } else {
              this.chatNavDrawer.close();
            }
          }
          if (this.userNavDrawer) {
            if (state[DrawerType.USER]) {
              this.userNavDrawer.open();
            } else {
              this.userNavDrawer.close();
            }
          }
        });
    });
  }

  ngOnDestroy() {
    this.mobileDrawerService.resetDrawerState();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
