/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
  Renderer2,
  ElementRef,
  AfterViewInit,
  ViewChild,
  RendererFactory2,
  ChangeDetectorRef,
} from '@angular/core';
import {
  AIMessage,
  SourceDoc,
  FactWithSource,
} from '../interfaces/chart-details.interface';

import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TooltipPositionService } from './chat-tooltip-position.service';

@Component({
  selector: 'app-chat-message-facts',
  encapsulation: ViewEncapsulation.None,

  styleUrls: ['./chat-message-facts.component.scss'],
  templateUrl: './chat-message-facts.component.html',
})
export class MessageFactsComponent implements OnInit, AfterViewInit {
  @ViewChild('factsContainer', { static: false }) factsContainer: ElementRef;

  @Input() aiMessage: AIMessage;
  @Output() citationClicked = new EventEmitter<{
    article: SourceDoc;
  }>();
  joinedMessages: string;
  factsWithSources: FactWithSource[] = [];
  hoveredDoc: SourceDoc | null = null;
  tooltipPosition: { left: number; top: number } | null = null;
  isFactsHtmlFormatted: boolean = false;
  sanitizedFactsHtml: SafeHtml;
  private renderer: Renderer2;

  private hideTooltipTimeout: any;
  private currentHoveredDocId: string | null = null;

  constructor(
    private tooltipPositionService: TooltipPositionService,
    rendererFactory: RendererFactory2,
    private sanitizer: DomSanitizer,
    private cdr: ChangeDetectorRef,
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  ngOnInit(): void {
    this.factsWithSources = this.hasFacts()
      ? this.processFactsWithSources()
      : [];
    if (this.hasFacts()) {
      this.isFactsHtmlFormatted = this.factsWithSources[0]?.fact.includes('<');
      if (this.isFactsHtmlFormatted) {
        const factsHtml = this.combineFactsHtml(this.factsWithSources);
        this.sanitizedFactsHtml =
          this.sanitizer.bypassSecurityTrustHtml(factsHtml);
      }
    } else {
      this.joinedMessages = this.processMessages();
    }
  }

  ngAfterViewInit(): void {
    if (this.hasFacts() && this.isFactsHtmlFormatted) {
      this.replacePlaceholdersWithCitations();
    }
  }

  private combineFactsHtml(facts: FactWithSource[]): string {
    if (!facts?.length) return '';

    return facts
      .map((fact) => {
        if (!fact?.fact) return '';

        let factHtml = fact.fact.trim();
        if (factHtml.includes('[theScreener]')) {
          factHtml = factHtml.replace(
            /\[theScreener\]/g,
            `<span class="citation__container"><a class="citation-item citation-item--screener" href="https://www.thescreener.com/en/method/" target="_blank" rel="noopener noreferrer">theScreener</a></span>`,
          );
        }
        if (factHtml.includes('[YUKKA LAB]')) {
          factHtml = factHtml.replace(/[\s\u00A0]*\[YUKKA LAB\]/g, '');
        }
        if (fact.source_docs?.length) {
          const citations = fact.source_docs
            .map((doc: SourceDoc) => {
              if (!doc?.id) return '';
              return `<span class="citation-placeholder" data-id="${doc.id}"></span>`;
            })
            .filter(Boolean)
            .join('');

          factHtml += citations;
        }
        return factHtml;
      })
      .join('')
      .trim();
  }

  private replacePlaceholdersWithCitations(): void {
    const placeholders = this.factsContainer.nativeElement.querySelectorAll(
      '.citation-placeholder',
    );
    if (!placeholders?.length) return;

    placeholders.forEach((placeholder) => {
      const citationContainer = this.renderer.createElement(
        'div',
      ) as HTMLElement;
      const id: string = placeholder.getAttribute('data-id');
      const document = this.aiMessage.source_documents_events?.find(
        (doc: SourceDoc) => doc.id === id,
      );
      if (!document) return;

      this.renderer.addClass(citationContainer, 'citation__container');

      const citationElement = this.renderer.createElement('div');

      this.renderer.addClass(citationElement, 'citation-item');

      const citationText = this.renderer.createText(`${document.citation}`);

      this.renderer.appendChild(citationElement, citationText);

      this.renderer.appendChild(citationContainer, citationElement);

      this.attachCitationEvents(citationContainer, document);

      placeholder.replaceWith(citationContainer);
    });
  }
  private attachCitationEvents(
    citationContainer: HTMLElement,
    document: SourceDoc,
  ): void {
    this.renderer.listen(citationContainer, 'click', () =>
      this.onCitationClick(document),
    );
    this.renderer.listen(citationContainer, 'mouseenter', (event: MouseEvent) =>
      this.onCitationMouseEnter(event, document),
    );
    this.renderer.listen(citationContainer, 'mouseleave', () =>
      this.onCitationMouseLeave(),
    );
  }

  private hasFacts(): boolean {
    return this.aiMessage.facts && this.aiMessage.facts.length > 0;
  }
  formatFact(fact: string): string {
    if (!fact) return '';
    return fact
      .trim()
      .replace(/\n\n/g, '<br><br>')
      .replace(/\n/g, '<br>')
      .replace(/\*\*(.*?)\*\*/g, '<b>$1</b>')
      .replace(/\*(.*?)\*/g, '<i>$1</i>')
      .replace(/###\s*(\w+)/g, '<h3>$1:</h3>')
      .replace(/####\s*(\w+)/g, '<h4>$1:</h4>');
  }

  processMessages(): string {
    const message = this.aiMessage.messages
      .map((msg: string) => msg.trim())
      .join(' ')
      .replace(/\n\n/g, '<br><br>')
      .replace(/\n/g, '<br>');
    return message;
  }

  processFactsWithSources(): FactWithSource[] {
    const factsWithSource = this.aiMessage?.facts?.map((fact) => {
      return {
        fact: fact.fact,
        source_docs: fact.source_doc_ids.map((sourceDocId: string) => {
          const sourceDoc = this.aiMessage.source_documents_events?.find(
            (doc: SourceDoc) => doc.id === sourceDocId,
          );
          return {
            id: sourceDocId,
            title: sourceDoc?.title,
            publish_time: sourceDoc?.publish_time,
            provider: sourceDoc?.provider,
            eventNames: sourceDoc?.eventNames,
            entityNames: sourceDoc?.entityNames,
            citation: sourceDoc?.citation,
          };
        }),
      };
    });

    return factsWithSource;
  }

  onCitationClick(article: SourceDoc): void {
    this.citationClicked.emit({ article });
  }

  onCitationMouseEnter(event: MouseEvent, doc: SourceDoc): void {
    clearTimeout(this.hideTooltipTimeout);
    if (this.currentHoveredDocId !== doc.id) {
      this.currentHoveredDocId = doc.id;
      this.hoveredDoc = doc;
      this.tooltipPosition = this.calculateTooltipPosition(event);
      this.cdr.detectChanges();
    }
  }

  onCitationMouseLeave(): void {
    this.hideTooltipTimeout = setTimeout(() => {
      this.hideTooltip();
      this.currentHoveredDocId = null;
    }, 100);
  }

  @HostListener('document:mousewheel', ['$event'])
  onDocumentMousewheelEvent(): void {
    if (this.hoveredDoc) {
      this.hideTooltip();
    }
  }

  // calculate the tooltip position based on citation position and chat wrapper dimensions
  // calculate the top position based on the space available above and below the citation
  // calculate the left position based on the space available on the left and right side of the chat wrapper
  private calculateTooltipPosition(event: MouseEvent): {
    left: number;
    top: number;
  } {
    const chatWrapperRect = this.getChatWrapperRect(
      event.target as HTMLElement,
    );
    return this.tooltipPositionService.calculateTooltipPosition(
      event,
      chatWrapperRect,
    );
  }

  private getChatWrapperRect(citationElement: HTMLElement): DOMRect {
    const chatWrapper = citationElement.closest(
      '.chat__wrapper',
    ) as HTMLElement;
    return chatWrapper.getBoundingClientRect();
  }

  private hideTooltip(): void {
    this.hoveredDoc = null;
    this.tooltipPosition = null;
    this.currentHoveredDocId = null;
  }
}
