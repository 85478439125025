/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit, Type, OnDestroy } from '@angular/core';
import { BreakpointService } from '../shared/services/breakpoints.service';
import { MobileLayoutComponent } from '../project/mobile/mobile-layout/mobile-layout.component';
import { CockpitComponent } from '../cockpit/cockpit.component';
import { Subscription } from 'rxjs';

@Component({
  template: `<ng-container *ngComponentOutlet="component"></ng-container>`,
})
export class DynamicContainerComponent implements OnInit, OnDestroy {
  component: Type<any>;
  private subscription: Subscription;

  constructor(private breakpointService: BreakpointService) {}

  ngOnInit() {
    this.subscription = this.breakpointService.screenSize$.subscribe(
      (screen: { isMobile: boolean }) => {
        this.component = screen.isMobile
          ? MobileLayoutComponent
          : CockpitComponent;
      },
    );
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
